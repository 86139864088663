import React, { Fragment } from 'react';
import { Image } from 'react-native';
import { Grid, Paper, Typography, useMediaQuery, useTheme } from '@mui/material';

const AccountUpgradeDetail = () => {
    const theme = useTheme();
    const isMatch = useMediaQuery(theme.breakpoints.down('md'));
    const imageHeight = isMatch ? 200 : 400;

    return (
        <Fragment>
            <Grid container spacing={0} sx={{ m: 0 }} >
                <Grid item xs={12} sm={12}  >
                    <Paper sx={{ padding: 2, mb: 2, pl: { sm: 2, md: 30 }, pr: { sm: 2, md: 30 }, height: '100%', bgcolor: 'black', color: 'white' }}>
                        <Typography variant="h5" sx={{ pb: 3 }}>Account Managment: How to upgrade an NBFire Free account</Typography>
                    </Paper>
                </Grid>
                <Grid item xs={12} sm={12} md={5} >
                    <Paper sx={{ padding: 2, mb: 2, pl: { sm: 2, md: 30 }, height: '100%', bgcolor: 'black', color: 'white' }}>
                        <Typography variant="body1" sx={{ pb: 3 }}><b>Navigate to Account:</b> Navigate to the Account management page using the 'Account' link in the top menu.</Typography>
                        <Typography variant="body1" sx={{ pb: 3 }}><b>Sign-in:</b> If promted, sign-in to your NBFire account.</Typography>
                        <Typography variant="body1" sx={{ pb: 3 }}><b>Upgrade Account:</b> On the account details page press the 'Upgrade Account' button.</Typography>
                    </Paper>
                </Grid>
                <Grid item xs={12} sm={12} md={7} >
                    <Paper sx={{ padding: 2, mb: 2, pr: { sm: 2, md: 30 }, height: '100%', bgcolor: 'black', color: 'white' }}>
                    <Image style={{height: imageHeight }} resizeMode="contain" 
                        source={{uri: 'https://nbfire-images.s3.amazonaws.com/nbfire-account-details.webp',}} 
                        alt={'NBFire Account Details'}
                    />
                    </Paper>
                </Grid>
                <Grid item xs={12} sm={12} md={5} >
                    <Paper sx={{ padding: 2, mb: 2, pl: { sm: 2, md: 30 }, height: '100%', bgcolor: 'black', color: 'white' }}>
                        <Typography variant="body1" sx={{ pb: 3 }}><b>Select New Account Type:</b> From the provided options, select the new account type you wish to move to.</Typography>
                        <Typography variant="body1" sx={{ pb: 3 }}><b>Provide Payment Details:</b> You will be re-directed to Stripe to provide the payment details, if applicable this will include a Trial preriod before payment is taken.</Typography>
                        <Typography variant="body1" sx={{ pb: 3 }}><b>Complete Upgrade:</b> Once complete, you will redirected to application, you can view your current account details at any time. If you have any issue then please contact us using either the provided form or email address.</Typography>
                    </Paper>
                </Grid>
                <Grid item xs={12} sm={12} md={7} >
                    <Paper sx={{ padding: 2, mb: 2, pr: { sm: 2, md: 30 }, height: '100%', bgcolor: 'black', color: 'white' }}>
                    <Image style={{height: imageHeight }} resizeMode="contain" 
                        source={{uri: 'https://nbfire-images.s3.amazonaws.com/nbfire-account-upgrade.webp',}} 
                        alt={'NBFire Account Details'}
                    />
                    </Paper>
                </Grid>
            </Grid>
        </Fragment >
    )
}

export default AccountUpgradeDetail