import React, { Fragment } from 'react';
import { Image } from 'react-native';
import { Grid, Paper, Typography, useMediaQuery, useTheme } from '@mui/material';

const GoogleSheetsDetail = () => {
    const theme = useTheme();
    const isMatch = useMediaQuery(theme.breakpoints.down('md'));
    const imageHeight = isMatch ? 250 : 400;

    return (
        <Fragment>
            <Grid container spacing={0} sx={{ m: 0 }} >
                <Grid item xs={12} sm={12}  >
                    <Paper sx={{ padding: 2, mb: 2, pl: { sm: 2, md: 30 }, pr: { sm: 2, md: 30 }, height: '100%', bgcolor: 'black', color: 'white' }}>
                        <Typography variant="h5" sx={{ pb: 3 }}>Google Sheets: How to use within NBFire</Typography>
                        <Typography variant="h6" sx={{ pb: 3 }}>You can download the example notebook used in this example from: <a href="https://examplenotebooks.s3.amazonaws.com/nbfire_and_google_sheets.ipynb" style={{ textDecoration: "underline" }}>here</a>.</Typography>
                    </Paper>
                </Grid>
                <Grid item xs={12} sm={12} md={5} >
                    <Paper sx={{ padding: 2, mb: 2, pl: { sm: 2, md: 30 }, height: '100%', bgcolor: 'black', color: 'white' }}>
                        <Typography variant="body1" sx={{ pb: 3 }}>This simple notebook sets out how you can use NBFire to regularly append data to a google sheet.  Each time this notebook runs, a new timestamp is created and appended to Google Sheet data.  We then read that data back into the Notebook using Pandas.</Typography>
                        <Typography variant="body1" sx={{ pb: 3 }}>You will need to set up your gsheet account as described in <a href="https://ploomber.io/blog/gsheets/" style={{ textDecoration: "underline" }}>https://ploomber.io/blog/gsheets/</a>. </Typography>
                        <Typography variant="body1" sx={{ pb: 3 }}>Upload the notebook to NBFire and select once processing has completed. In the Configure Schedule panel, add your gsheet creds as a parameter. Set the schedule and click the Fire button to run regularly.</Typography>
                        <Typography variant="body1" sx={{ pb: 3 }}>Check your output notebooks and Google Sheet to see the timestamps being generated. You can easily adapt this notebook to pull data from different sources, use Google Sheets to store that data and then append it during the next scheduled run.</Typography>
                    </Paper>
                </Grid>
                <Grid item xs={12} sm={12} md={7} >
                    <Paper sx={{ padding: 2, mb: 2, pr: { sm: 2, md: 30 }, height: '100%', bgcolor: 'black', color: 'white' }}>
                    <Image style={{height: imageHeight }} resizeMode="contain" 
                        source={{uri: 'https://nbfire-images.s3.amazonaws.com/google_sheets_nbfire_example.webp',}} 
                        alt={'NBFire Google Sheets Jupyter Notebook'}
                    />
                    </Paper>
                </Grid>
                <Grid item xs={12} sm={12} md={12} >
                    <Paper sx={{ padding: 2, mb: 2, pl: { sm: 2, md: 30 }, pr: { sm: 2, md: 30 }, height: '100%', bgcolor: 'black', color: 'white' }}>
                        <Typography variant="body1" sx={{ pb: 3 }}><b>How it works</b></Typography>
                        <Typography variant="body1" sx={{ pb: 3 }}>The notebooks works by first loading the provided creds into a dict and using this to instantiate a gspread service account:</Typography>
                        <Paper sx={{ padding: 2, mb: 2, width: '100%', bgcolor: '#607d8b', color: 'black' }}>
                            <Typography variant="body1" sx={{ pb: 3 }}>credentials = json.loads(creds)</Typography>
                            <Typography variant="body1" sx={{ pb: 0 }}>sa = gspread.service_account_from_dict(credentials)</Typography>
                        </Paper>
                        <Typography variant="body1" sx={{ pb: 3 }}>The service account can then be used to open the google sheet and set the specific worksheet within this that we wish to use:</Typography>
                        <Paper sx={{ padding: 2, mb: 2, width: '100%', bgcolor: '#607d8b', color: 'black' }}>
                            <Typography variant="body1" sx={{ pb: 3 }}>sheet = sa.open("Test")</Typography>
                            <Typography variant="body1" sx={{ pb: 0 }}>work_sheet = sheet.worksheet("Sheet1")</Typography>
                        </Paper>
                        <Typography variant="body1" sx={{ pb: 3 }}>The 'work_sheet' can then be used to fetch and set data within the google sheet:</Typography>
                        <Paper sx={{ padding: 2, mb: 2, width: '100%', bgcolor: '#607d8b', color: 'black' }}>
                            <Typography variant="body1" sx={{ pb: 3 }}>work_sheet.get_all_records() # This can be loaded into a pandas dataframe as shown in the example notebook</Typography>
                            <Typography variant="body1" sx={{ pb: 0 }}>work_sheet.append_row(some_value)</Typography>
                        </Paper>
                    </Paper>
                </Grid>
            </Grid>
        </Fragment >
    )
}

export default GoogleSheetsDetail