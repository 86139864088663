import React from 'react'
import { Helmet } from 'react-helmet'
import Footer from '../components/general/Footer'
import HeroImage from '../components/general/HeroImage'
import Navbar from '../components/general/Navbar'
import DocLinks from '../components/documentation/DocLinks'
import GoogleSheetsDetail from '../components/examples/GoogleSheetsDetail'

const ExampleGoogleSheets = () => {
    return (
        <div>
            <Helmet>
                <title>NBFire | Examples | Google Sheets</title>
                <meta
                    name="description"
                    content="An example of using Google Sheets within NBFire to fetch and update an external datasource"
                />
                <meta
                    name="keywords"
                    content="Jupyter Notebook fetch Google Sheets, Jupyter Notebook update Google Sheets, Jupyter Notebook Google Sheets pandas, using pandas gspread, scheduling google sheets updates"
                />
            </Helmet>
            <Navbar />
            <HeroImage heading='Resources' />
            <DocLinks/>
            <GoogleSheetsDetail />
            <Footer />
        </div>
    )
}

export default ExampleGoogleSheets