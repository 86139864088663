import React from 'react'
import { Grid, List, ListItem, ListItemButton, ListItemText, Paper, Stack, Typography } from '@mui/material';
import { Link } from 'react-router-dom';

const DocLinks = () => {

    return (
        <Paper sx={{ mt: 2, mb: 2, ml: { sm: 2, md: 30 }, mr: { sm: 2, md: 30 }, height: '100%', bgcolor: 'black', color: 'white', border: 3, borderRadius: '10px', borderColor: 'white' }}>
            <Grid container spacing={0} sx={{ m: 0 }} >
                <Grid item xs={12} sm={4}  >
                    <Typography variant="h6" sx={{ pl: 2, pt: 2 }}>Guides</Typography>
                    <List component={Stack} direction="column">
                        <ListItem disablePadding>
                            <Link to="/resources">
                                <ListItemButton>
                                    <ListItemText primary="Quickstart" />
                                </ListItemButton>
                            </Link>
                        </ListItem>
                        <ListItem disablePadding>
                            <Link to="/resources/upgradeaccount">
                                <ListItemButton>
                                    <ListItemText primary="Upgrade a Trial Account" />
                                </ListItemButton>
                            </Link>
                        </ListItem>
                        <ListItem disablePadding>
                            <Link to="/resources/timezones">
                                <ListItemButton>
                                    <ListItemText primary="Timezones and Schedules" />
                                </ListItemButton>
                            </Link>
                        </ListItem>
                        <ListItem disablePadding>
                            <Link to="/resources/hidingparameters">
                                <ListItemButton>
                                    <ListItemText primary="Hiding Sensitive Data" />
                                </ListItemButton>
                            </Link>
                        </ListItem>
                    </List>
                </Grid>
                <Grid item xs={12} sm={4}  >
                    <Typography variant="h6" sx={{ pl: 2, pt: 2 }}>Examples</Typography>
                    <List component={Stack} direction="column">
                        <ListItem disablePadding>
                            <Link to="/resources/googlesheets">
                                <ListItemButton>
                                    <ListItemText primary="Google Sheets" />
                                </ListItemButton>
                            </Link>
                        </ListItem>
                    </List>
                </Grid>
                <Grid item xs={12} sm={4}  >
                    <Typography variant="h6" sx={{ pl: 2, pt: 2 }}>News</Typography>
                    <List component={Stack} direction="column">
                        <ListItem disablePadding>
                            <Link to="/resources/packageprocessing">
                                <ListItemButton>
                                    <ListItemText primary="Required Packages" />
                                </ListItemButton>
                            </Link>
                        </ListItem>
                    </List>
                </Grid>
            </Grid>
        </Paper>
    )
}

export default DocLinks