import React from 'react';
import { Helmet } from 'react-helmet';
import Footer from '../components/general/Footer';
import HeroImage from '../components/general/HeroImage';
import Navbar from '../components/general/Navbar';
import DocLinks from '../components/documentation/DocLinks';
import PackageInstallDetail from '../components/news/PackageInstallDetail';

const NewsPackaging = () => {
    return (
        <div>
            <Helmet>
                <title>NBFire | News | Package Requirments</title>
                <meta
                    name="description"
                    content="NBFire will now automaticaly process Jupyter Notebooks to extract the package requirements. These will then be intalled before running your scheduled notebook"
                />
                <meta
                    name="keywords"
                    content="Jupyter Notebooks package requirements, Jupyter Notebooks automatically install packages, Jupyter Notebook New NBFire features"
                />
            </Helmet>
            <Navbar />
            <HeroImage heading='Resources' />
            <DocLinks/>
            <PackageInstallDetail />
            <Footer />
        </div>
    )
}

export default NewsPackaging