import React from 'react';
import { Helmet } from 'react-helmet';
import Footer from '../components/general/Footer';
import HeroImage from '../components/general/HeroImage';
import Navbar from '../components/general/Navbar';
import DocumentationDetails from '../components/documentation/DocumentationDetails';
import DocLinks from '../components/documentation/DocLinks';

const Documentation = () => {
    return (
        <div>
            <Helmet>
                <title>NBFire | Resources | Quick Start</title>
                <meta
                    name="description"
                    content="Guide on how to begin to schedule your notebooks in the cloud"
                />
                <meta
                    name="keywords"
                    content="NBFire Jupyter Notebooks, Jupyter Notebook Quick Start Guide, Jupyter Notebook Cloud Execution"
                />
            </Helmet>
            <Navbar />
            <HeroImage heading='Resources' />
            <DocLinks/>
            <DocumentationDetails />
            <Footer />
        </div>
    )
}

export default Documentation