import React, { Fragment } from 'react';
import { Image } from 'react-native';
import { Grid, Paper, Typography, useMediaQuery, useTheme } from '@mui/material';

const TimezoneDetails = () => {
    const theme = useTheme();
    const isMatch = useMediaQuery(theme.breakpoints.down('md'));
    const imageHeight = isMatch ? 250 : 400;

    return (
        <Fragment>
            <Grid container spacing={0} sx={{ m: 0 }} >
                <Grid item xs={12} sm={12}  >
                    <Paper sx={{ padding: 2, mb: 2, pl: { sm: 2, md: 30 }, pr: { sm: 2, md: 30 }, height: '100%', bgcolor: 'black', color: 'white' }}>
                        <Typography variant="h5" sx={{ pb: 3 }}>Timezones: How to schedule Jupyter Notebooks in any Timezone!</Typography>
                    </Paper>
                </Grid>
                <Grid item xs={12} sm={12} md={5} >
                    <Paper sx={{ padding: 2, mb: 2, pl: { sm: 2, md: 30 }, height: '100%', bgcolor: 'black', color: 'white' }}>
                        <Typography variant="body1" sx={{ pb: 3 }}><b>Select Notebook:</b> Click on the notebook panel you wish to schedule</Typography>
                        <Typography variant="body1" sx={{ pb: 3 }}><b>Select Timezone:</b> In the Configure Schedule panel, just use the Timezone dropdown to select the required timezone! (If the timezone you require is missing please contact us to get it added)</Typography>
                        <Typography variant="body1" sx={{ pb: 3 }}><b>Set Schedule:</b> The Minutes/Hours/Days can be configured using the schedule builder settings, in the example shown this is for "America/Los_Angeles at 9am every day."</Typography>
                        <Typography variant="body1" sx={{ pb: 3 }}><b>Submit Schedule:</b> Click the 'Fire Schedule' button to submit and enable the schedule to run your Jupyter Notebooks! This can be enabled/disabled using the toggle on the Schedules panel"</Typography>
                    </Paper>
                </Grid>
                <Grid item xs={12} sm={12} md={7} >
                    <Paper sx={{ padding: 2, mb: 2, pr: { sm: 2, md: 30 }, height: '100%', bgcolor: 'black', color: 'white' }}>
                    <Image style={{height: imageHeight }} resizeMode="contain" 
                        source={{uri: 'https://nbfire-images.s3.amazonaws.com/nbfire-schedule-timezones.webp',}} 
                        alt={'NBFire Schedule Panel'}
                    />
                    </Paper>
                </Grid>
            </Grid>
        </Fragment >
    )
}

export default TimezoneDetails