import React, { Fragment } from 'react';
import { List, ListItem, ListItemText, Paper, Typography } from '@mui/material';

const TermsContent = () => {

    return (
        <Fragment>
            <Paper sx={{ padding: 2, mb: 2, pl: { sm: 2, md: 30 }, pr: { sm: 2, md: 30 }, height: '100%', bgcolor: 'black', color: 'white' }}>
                <Typography variant="h6">These Terms of Service constitute a legally enforceable contract between you and NBFire, which governs your use of our Website.</Typography>
                <Typography variant="h6">“PLEASE READ THE TERMS BELOW CAREFULLY BY CLICKING "I ACCEPT," OR OTHERWISE. BY ACCESSING OR USING OUR WEBSITE, YOU AGREE THAT YOU HAVE READ AND UNDERSTOOD THE FOLLOWING TERMS AND CONDITIONS, INCLUDING the PRIVACY POLICY OF NBFIRE.“</Typography>
                <List sx={{ listStyle: "decimal" }}>
                <ListItem sx={{ display: "list-item" }}>
                        <ListItemText primary="NBFire Website Overview: The Site is designed to provide you with information about services offered by NBFire as well as for providing the necessary means to contact NBFire." />
                    </ListItem>
                    <ListItem sx={{ display: "list-item" }}>
                        <ListItemText primary='Proprietary Rights: NBFire owns and operates the Site. Other laws safeguard the visual interfaces, graphics, design, compilation, information, data, computer code (including source code or object code), software, services, and any other parts of the Site ("Materials") offered by NBFire. The Sites materials are the property of NBFire or its third-party licensors. You may not utilise the materials unless specifically allowed by NBFire. NBFire retains all rights to the materials not expressly granted in these Terms.' />
                    </ListItem>
                    <ListItem sx={{ display: "list-item" }}>
                        <ListItemText primary="Free Trial: A one month free trial is available for Standard and Premium accounts. In order to access the free trial valid payment details must be provided up-front. If the account isn't canceled or switched to Free account then at the end of the trial period the payment details will be charged at the appropriate rate for the account type." />
                    </ListItem>
                    <ListItem sx={{ display: "list-item" }}>
                        <ListItemText primary='Feedback: If you choose to submit feedback and recommendations regarding problems with or suggested changes or additions to the Site ("Feedback"), you thus grant NBFire an unlimited, permanent, irrevocable, non-exclusive to utilise the Feedback in any form and for any purpose, including improving the Site and developing new and services.' />
                    </ListItem>
                    <ListItem sx={{ display: "list-item" }}>
                        <ListItemText primary="Third-Party Websites: Third-party websites may be linked to our website. However, NBFire has no control over the linked websites and is not responsible for their content." />
                    </ListItem>
                    <ListItem sx={{ display: "list-item" }}>
                        <ListItemText primary="Prohibited Behaviour. BY ACCESSING THIS WEBSITE, YOU AGREE NOT TO:" />
                        <List sx={{ listStyle: "decimal", ml: 2, mr: 2 }}>
                            <ListItem sx={{ display: "list-item" }}>
                                <ListItemText primary='Use the Site for any illicit purposes or in violation of any local, state, national, or international law.' />
                            </ListItem>
                            <ListItem sx={{ display: "list-item" }}>
                                <ListItemText primary="Tamper with the site's security features, such as by: (i) turning off or getting around restrictions on the use or copying of content; or (ii) trying to figure out the source code of any part of the site through reverse engineering or another method unless doing so is specifically authorised by the law." />
                            </ListItem>
                            <ListItem sx={{ display: "list-item" }}>
                                <ListItemText primary='By, for example, (i) uploading or otherwise propagating any virus, adware, spyware, worm, or other harmful code; (ii) tampering with or disrupting any network, equipment, or server connected to or utilised to offer the Site.' />
                            </ListItem>
                            <ListItem sx={{ display: "list-item" }}>
                                <ListItemText primary="Assisting or permitting any person to engage in any of the acts mentioned in this Section 6, you may harm the Site's functionality or any user's." />
                            </ListItem>
                            <ListItem sx={{ display: "list-item" }}>
                                <ListItemText primary='Users are strictly prohibited from conducting or facilitating Distributed Denial-of-Service (DDoS) attacks. Any detected involvement in such activities will result in immediate termination of your account.' />
                            </ListItem>
                        </List>
                    </ListItem>
                    <ListItem sx={{ display: "list-item" }}>
                        <ListItemText primary="Changes to these Terms: We retain the right to amend these Terms at any moment in the future. Please review these Terms on a regular basis for modifications. Material changes become effective upon your acceptance of the revised Terms. Except as clearly provided in this Section 6, these Terms may only be modified in writing by authorised representatives of the parties to these Terms. Disputes under these Terms shall be settled in accordance with the version of these Terms in force at the time the disagreement arose." />
                    </ListItem>
                    <ListItem sx={{ display: "list-item" }}>
                        <ListItemText primary="Site Terms, Termination, and Modification:" />
                        <List sx={{ listStyle: "decimal", ml: 2, mr: 2 }}>
                            <ListItem sx={{ display: "list-item" }}>
                                <ListItemText primary='Term. These Terms take effect when you accept them or first access or use the Site, and they expire when you terminate as specified in Section 8.2' />
                            </ListItem>
                            <ListItem sx={{ display: "list-item" }}>
                                <ListItemText primary="Termination. If you breach any of these Terms, your permission to use the Site and these Terms will be revoked. Furthermore, NBFire has the right to terminate these Terms or stop or terminate your access to the Site at any time, for any or no reason, with or without notice." />
                            </ListItem>
                            <ListItem sx={{ display: "list-item" }}>
                                <ListItemText primary="The Case where the site has been modified. Without providing you with prior notice, NBFire maintains the right to change or stop operating the Site at any time (including by restricting or stopping the availability of specific services). If the Site is altered or if your access to or usage of the Site is suspended or terminated, NBFire disclaims all the liabilities." />
                            </ListItem>
                        </List>
                    </ListItem>
                    <ListItem sx={{ display: "list-item" }}>
                        <ListItemText primary='Insurance policy: You are accountable for your use of the Site to the fullest extent permitted by law, and you undertake to defend and hold NBFire and its officers, directors, employees, consultants, affiliates, subsidiaries, and agents (collectively, the "NBFire Entities") harmless from and against any third-party claims and any associated responsibility, damage, loss, and expense, including reasonable attorneys fees and expenses, arising out of or connected with: (a) your unauthorised use of, or misappropriation of, any information obtained from the Site; (b) your failure to comply with any provision of these Terms, any representation, warranty, or agreement referred to herein, or any relevant law or regulation; (c) your failure to comply with any right of a third party, such as a right of publicity, confidentiality, or other property or privacy; (d) Data; or (e) any problem or issue that you have with a third party.' />
                    </ListItem>
                    <ListItem sx={{ display: "list-item" }}>
                        <ListItemText primary='No warranties: The site is provided "as is" and on a "as available" basis, together with any materials and content made available through the site. NBFire disclaims all warranties, express or implied, as to the site and all data, materials, and content accessible through the site, including (a) any implied warranty of merchantability, fitness for a particular purpose, title, quiet enjoyment, or non-infringement; and (b) NBFire is a secure platform however we do not warrant the modifications, changes or any tampering done by the users. We are not liable for any loss or damage that may result from the use of our website or your dealings with other site users. You acknowledge and agree that your use of any portion of the site is done at your own risk and discretion, and that we shall not be liable for any damage to your property, including any damage to your computer system or mobile device used in connection with the site, or for any loss of data. These limitations mentioned in this section, exclusions, and disclaimers apply to the fullest extent permitted by law. No guarantee or other right that RunCode is not allowed to disclaim by relevant legislation is made by NBFire.' />
                    </ListItem>
                    <ListItem sx={{ display: "list-item" }}>
                        <ListItemText primary='Defining the limits of Liability. To the maximum extent permitted by law, the NBFire entities will not be liable to you for any indirect, incidental, special, consequential, or punitive damages (including damages for loss of profits, goodwill, or any other intangible loss) originating out of or pertaining to your access to or use of, or inability to. Each clause in these terms that limits liability, disclaims warranties, or excludes damages is intended to and really divide the risks between the parties under these clauses. This allocation is a necessary element of the bargain between the parties. All of these provisions are severable and independent of the others in these terms. Even if any limited remedy fails for its essential purpose, the limitations in this section will apply.' />
                    </ListItem>
                    <ListItem sx={{ display: "list-item" }}>
                        <ListItemText primary='Miscellaneous:' />
                        <List sx={{ listStyle: "decimal", ml: 2, mr: 2 }}>
                            <ListItem sx={{ display: "list-item" }}>
                                <ListItemText primary='General Terms. These Terms constitute the full and only understanding and agreement between you and NBFire with respect to your use of the Site, along with the Privacy Policy and any other agreements explicitly included by reference into these Terms. Without our prior written approval, you may not assign or otherwise transfer these Terms or your rights under them in whole or in part, under any circumstances. We reserve the right to assign these Terms at any time and without notice or consent. A waiver by us of any breach or default of these Terms or any provision hereof will not be construed as a waiver of any future breach or default or as a waiver of the provision itself, nor will it affect our right to compel performance of such provision at any other time after such waiver. The inclusion of section headers in these Terms is only for convenience and has no bearing on the interpretation of any provision.' />
                            </ListItem>
                            <ListItem sx={{ display: "list-item" }}>
                                <ListItemText primary="Privacy Policy. Unless otherwise specified in these Terms, any personal information you enter into the Site or that we collect from you in providing the Site will be treated in accordance with the NBFire Privacy Policy. These Terms comprise the NBFire Privacy Policy and are incorporated therein by reference." />
                            </ListItem>
                            <ListItem sx={{ display: "list-item" }}>
                                <ListItemText primary='Data. Any data and information (including personal data) that you enter into, send to, or use in connection with the Site (collectively, "Data") are solely your responsibility. You are also solely responsible for adhering to any privacy or data protection laws and regulations that may be relevant to Data and your use of the Site. You hereby allow RunCode the right to collect, process, transmit, store, use, and disclose Data in accordance with this Agreement and the NBFire Privacy Policy, as well as for other purposes.' />
                            </ListItem>
                            <ListItem sx={{ display: "list-item" }}>
                                <ListItemText primary='Data Analytics. You recognise and consent to NBFires right to gather, produce, process, transmit, store, utilise, and disclose aggregated and/or de-identified data resulting from Data or usage of the Site ("Analytics Data") for its business objectives, including for analytics, benchmarking, and industry analysis.' />
                            </ListItem>
                            <ListItem sx={{ display: "list-item" }}>
                                <ListItemText primary="Information safety. In order to safeguard the data in its custody or control from unauthorised or illegal access, use, modification, or disclosure, NBFire will put in place commercially reasonable security measures." />
                            </ListItem>
                            <ListItem sx={{ display: "list-item" }}>
                                <ListItemText primary="Electronic Communications Consent. You agree to receive some electronic communications from us by using the Site, as further defined in our Privacy Policy. To find out more about how we use electronic communications, please read our privacy policy. You acknowledge and agree that all notices, agreements, disclosures, and other communications that we deliver to you electronically satisfies any legal communication requirements, including the need that they be in writing." />
                            </ListItem>
                        </List>
                    </ListItem>
                </List>
            </Paper>
        </Fragment>
    )
}

export default TermsContent