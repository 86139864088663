
import React from 'react'
import Vimeo from '@u-wave/react-vimeo';
import './Video1Styles.css'


const Video1 = () => {
    return (
      <div className='videowidth'>
        <div>
          <Vimeo
            video="781765660"
            responsive
            muted={true}
        />
        </div>
      </div>
       
    )
}

export default Video1