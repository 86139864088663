import React from 'react'
import { Helmet } from 'react-helmet'
import Footer from '../components/general/Footer'
import HeroImage from '../components/general/HeroImage'
import Navbar from '../components/general/Navbar'
import DocLinks from '../components/documentation/DocLinks'
import TimezoneDetails from '../components/documentation/TimezoneDetails'

const DocTimezones = () => {
    return (
        <div>
            <Helmet>
                <title>NBFire | Resources | Schduling Jupyter Notebooks</title>
                <meta
                    name="description"
                    content="How to easiy Schedule your Jupyter Notebook in any timezone with a few clicks!"
                />
                <meta
                    name="keywords"
                    content="NBFire Schdule Jupyter Notebooks, Schdule Jupyetr Notebooks, Timezone Schdules Data Science, Timezone Schdules Jupyter Notebooks"
                />
            </Helmet>
            <Navbar />
            <HeroImage heading='Resources' />
            <DocLinks/>
            <TimezoneDetails />
            <Footer />
        </div>
    )
}

export default DocTimezones