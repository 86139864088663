import { React }from 'react'
import { Helmet } from 'react-helmet';
import Footer from '../components/general/Footer'
import HeroImage from '../components/general/HeroImage'
import Navbar from '../components/general/Navbar'
import ContactUsPage from '../components/contact/ContactUsPage';


const Contact = () => {

    return (
        
        <div id="contact_page_header">
            <Helmet>
                <title>NBFire | Contact Us</title>
                <meta
                name="description"
                content="Contact us to find out how to run your Jupyter Notebooks in the cloud"
                />
             </Helmet>
            <Navbar />
            <HeroImage heading='Contact Us' />
            <ContactUsPage />
            <Footer/>
        </div>
    )
}

export default Contact