import React from 'react';
import { Helmet } from 'react-helmet-async';
import NavBar from '../components/general/Navbar';
import Footer from '../components/general/Footer';
import HeroImage from '../components/general/HeroImage';
import UserAccount from '../components/account/UserAccount';


const SignupWithCodeAccountDetails = () => {  
  return (
    <div>
      <Helmet>
        <title>NBFire | Signup</title>
        <meta
        name="description"
        content="Signup for NBFire with an account code and start Automating your notebooks today"
        />
        <meta
          name="keywords"
          content="NBFire account code verification"
        />
      </Helmet>
       <NavBar />
       <HeroImage heading="Signup with Account Code" text="Verify NBFire account code has been applied" />
       <UserAccount type="signIn" verifyCode={true}/>
       <Footer />
    </div>
  )
}

export default SignupWithCodeAccountDetails