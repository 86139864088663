import React, { useState } from 'react'
import { Helmet } from 'react-helmet-async'
import NavBar from '../components/general/Navbar'
import Footer from '../components/general/Footer'
import HeroImage from '../components/general/HeroImage'
import SelectAccountType from '../components/createaccount/SelectAccountType'
import UserAccount from '../components/account/UserAccount'


const Signup = () => {

  const [accountType, setAccountType] = useState();
  
  return (
    <div>
      <Helmet>
        <title>NBFire | Signup</title>
        <meta
        name="description"
        content="Signup for an NBFire account and start Automating your notebooks today"
        />
        <meta
          name="keywords"
          content="NBFire signup, signup to automate and schedule jupyter notebooks, cloud signup"
        />
      </Helmet>
       <NavBar />
       <HeroImage heading="Signup" text={!accountType ? "Signup for an NBFire account and start Automating your notebooks today" : "Create " + accountType + " account"} />
       {!accountType ? 
        <SelectAccountType setType={setAccountType}/>
        :
        <UserAccount signUpType={accountType}/>
       }
       <Footer />
    </div>
  )
}

export default Signup