import React from 'react'
import { Helmet } from 'react-helmet'
import SignUpHeader from '../components/account/SignUpHeader'
import UserAccount from '../components/account/UserAccount'
import Footer from '../components/general/Footer'
import Navbar from '../components/general/Navbar'


const Account = () => {
    return (
        <div>
            <Helmet>
                <title>NBFire | Manage Account</title>
                <meta
                name="description"
                content="Mange your NBFIRE account, upgrade you FREE account to a paid subscription to allow access to even more schduled notebook runs per month"
                />
             </Helmet>
            <Navbar />
            <SignUpHeader heading='Account' />
            <UserAccount type="signIn"/>
            <Footer/>
        </div>
    )
}

export default Account