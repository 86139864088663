import React from 'react';
import { Button } from '@mui/material';
import EastIcon from '@mui/icons-material/East';
import { Link } from 'react-router-dom';


const GetStartedButton = () => {
  
  return (
        <Button color="secondary" variant="contained" sx={{ width: '35em', pt: 1, pb: 1 }} component={Link} to={"/signup"}>
            Get Started<EastIcon sx={{pl: 1}}/>
        </Button>
  )
}

export default GetStartedButton