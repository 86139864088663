import React from 'react'
import { Helmet } from 'react-helmet-async'
import NavBar from '../components/general/Navbar'
import Footer from '../components/general/Footer'
import HeroImage from '../components/general/HeroImage'
import TermsContent from '../components/terms/TermsContent'

const Terms = () => {
  
  return (
    <div>
      <Helmet>
        <title>NBFire | Terms and Conditions</title>
        <meta
        name="description"
        content="Terms and Conditions for NBFire"
        />
        <meta
          name="keywords"
          content="NBFire terms of use, NBFire terms and conditions"
        />
      </Helmet>
       <NavBar />
       <HeroImage heading='Terms and Conditions'/>
       <TermsContent/>
       <Footer />
    </div>
  )
}

export default Terms