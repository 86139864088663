import React from 'react'
import { Helmet } from 'react-helmet-async'
import NavBar from '../components/general/Navbar'
import Footer from '../components/general/Footer'
import HeroImage from '../components/general/HeroImage'
import PrivacyContent from '../components/privacy/PrivacyContent'

const PrivacyPolicy = () => {
  
  return (
    <div>
      <Helmet>
        <title>NBFire | Privacy Policy</title>
        <meta
        name="description"
        content="Privacy Policy for NBFire"
        />
        <meta
          name="keywords"
          content="NBFire privacy policy"
        />
      </Helmet>
       <NavBar />
       <HeroImage heading='Privacy Policy' />
       <PrivacyContent/>
       <Footer />
    </div>
  )
}

export default PrivacyPolicy