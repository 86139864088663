import React from 'react'
import { Helmet } from 'react-helmet'
import Footer from '../components/general/Footer'
import HeroImage from '../components/general/HeroImage'
import Navbar from '../components/general/Navbar'
import DocLinks from '../components/documentation/DocLinks'
import AccountUpgradeDetail from '../components/documentation/AccountUpgradeDetail'

const DocAccountUpgrade = () => {
    return (
        <div>
            <Helmet>
                <title>NBFire | Resources | Upgrade Account</title>
                <meta
                    name="description"
                    content="How to upgrade your NBFire account to a piad subscription to allow even more jupyter notebook runs per month"
                />
                <meta
                    name="keywords"
                    content="NBFire Upgrade Account, NBFire account guidance"
                />
            </Helmet>
            <Navbar />
            <HeroImage heading='Resources' />
            <DocLinks/>
            <AccountUpgradeDetail />
            <Footer />
        </div>
    )
}

export default DocAccountUpgrade