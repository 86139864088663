import React from 'react';
import { Helmet } from 'react-helmet-async';
import NavBar from '../components/general/Navbar';
import Footer from '../components/general/Footer';
import HeroImage from '../components/general/HeroImage';
import HomeContent from '../components/home/HomeContent';


const Home = () => {

  return (
    <div>
      <Helmet>
        <title>NBFire | Automate and Schedule Jupyter Notebooks</title>
        <meta
          name="description"
          content="A Cloud based SaaS platform for automataing and scheduling your Jupyter Notebooks. Eaisly upload and Schedule your notebooks today without the need to setup and manage your own infrastructure."
        />
        <meta
          name="keywords"
          content="automate jupyter notebooks, schedule jupyter notebooks, scheduling jupyter notebooks, jupyter notebooks SaaS, Notebook, jupyter notebooks cloud"
        />
        <meta
          name="og:title"
          content="NBFire | Automate and Schedule Jupyter Notebooks"
        />
        <meta
          name="og:description"
          content="A Cloud based SaaS platform for automataing and scheduling your Jupyter Notebooks. Eaisly upload and Schedule your notebooks today without the need to setup and manage your own infrastructure."
        />
        <meta
          name="og:keywords"
          content="automate jupyter notebooks, schedule jupyter notebooks, scheduling jupyter notebooks, jupyter notebooks SaaS, Notebook, jupyter notebooks cloud"
        />
      </Helmet>
      <NavBar />
      <HeroImage/>  
      <HomeContent/>      
      <Footer />
    </div>
  )
}

export default Home