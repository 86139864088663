import React, { Fragment } from 'react';
import { Image } from 'react-native';
import { Grid, Paper, Typography, useMediaQuery, useTheme } from '@mui/material';

const PackageInstallDetail = () => {
    const theme = useTheme();
    const isMatch = useMediaQuery(theme.breakpoints.down('md'));
    const imageHeight = isMatch ? 250 : 350;

    return (
        <Fragment>
            <Grid container spacing={0} sx={{ m: 0 }} >
                <Grid item xs={12} sm={12}  >
                    <Paper sx={{ padding: 2, mb: 2, pl: { sm: 2, md: 30 }, pr: { sm: 2, md: 30 }, height: '100%', bgcolor: 'black', color: 'white' }}>
                        <Typography variant="h5" sx={{ pb: 3 }}>NBFire now installs your Jupyter Notebooks package requirements automatically!</Typography>
                    </Paper>
                </Grid>
                <Grid item xs={12} sm={12} md={5} >
                    <Paper sx={{ padding: 2, mb: 2, pl: { sm: 2, md: 30 }, height: '100%', bgcolor: 'black', color: 'white' }}>
                        <Typography variant="body1" sx={{ pb: 3 }}>Previously, in order to use packages from the public PyPi repository a Jupyter Notebook would need to install them using pip.</Typography>
                        <Typography variant="body1" sx={{ pb: 3 }}>This is now no longer required!</Typography>
                        <Typography variant="body1" sx={{ pb: 3 }}>As part of the notebook processing that NBFire perfroms, the packages required by a Jupyter Notebook will be extracted, these will be displayed on the Notebook panel as shown.</Typography>
                        <Typography variant="body1" sx={{ pb: 3 }}>When running the Notebook, the package requirements will be installed before execution of the Jupyter Notebook begins, all automatically!</Typography>
                    </Paper>
                </Grid>
                <Grid item xs={12} sm={12} md={7} >
                    <Paper sx={{ padding: 2, mb: 2, pr: { sm: 2, md: 30 }, height: '100%', bgcolor: 'black', color: 'white' }}>
                    <Image style={{height: imageHeight }} resizeMode="contain" 
                        source={{uri: 'https://nbfire-images.s3.amazonaws.com/NBFire-Required-Packages-Example.webp',}} 
                        alt={'NBFire Required Packages Example'}
                    />
                    </Paper>
                </Grid>
            </Grid>
        </Fragment >
    )
}

export default PackageInstallDetail