import React, { Fragment } from 'react';
import { Grid, Paper, Typography } from '@mui/material';
import { FaMailBulk } from 'react-icons/fa';

const ContactUsPage = () => {

    return (
        <Fragment>
            <Grid container spacing={0} sx={{ m: 0 }} >
                <Grid item xs={12} sm={12}  >
                    <Paper sx={{ padding: 2, mb: 2, pl: { sm: 2, md: 30 }, pr: { sm: 2, md: 30 }, height: '100%', bgcolor: 'black', color: 'white' }}>
                        <Typography variant="h5" sx={{ pb: 3 }}>Contact Details for NBFire</Typography>
                    </Paper>
                </Grid>
                <Grid item xs={12} sm={12} md={12} >
                    <Paper sx={{ padding: 2, mb: 2, pl: { sm: 2, md: 30 }, height: '100%', bgcolor: 'black', color: 'white' }}>
                        <Typography variant="body1" sx={{ pb: 3 }}>If you wish to contact us regarding anything then please use the email address below, we look forward to hearing from you!</Typography>
                        <Typography variant="body1" sx={{ pb: 3 }}><a href="mailto: contact@nbfire.io"><FaMailBulk size={20} style={{ color: '#FAF9F6', marginRight: '2rem' }} /> contact@nbfire.io</a></Typography>
                    </Paper>
                </Grid>
            </Grid>
        </Fragment >
    )
}

export default ContactUsPage