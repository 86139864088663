import React from 'react'
import './FooterStyles.css'
import { FaLinkedin, FaMailBulk } from 'react-icons/fa'
import { Link } from 'react-router-dom'
import PersonIcon from '@mui/icons-material/Person';
import ArticleIcon from '@mui/icons-material/Article';

const Footer = () => {
    return (
        <div className='footer'>
            <div className='footer-container'>
                <div className='left'>
                    <div className='email'>
                       <h4><a href="mailto: contact@nbfire.io"><FaMailBulk size={20} style={{ color: '#FAF9F6', marginRight: '2rem' }} /> contact@nbfire.io</a> </h4>
                    </div>
                    <div className='terms'>
                       <h4><Link to="/terms-and-conditions"><ArticleIcon sx={{mr:4}}/>Terms and Conditions</Link></h4>
                    </div>
                    <div className='privacy'>
                    <h4><Link to="/privacy-policy"><PersonIcon sx={{mr:4}}/>Privacy Policy</Link></h4>
                    </div>
                </div>
                <div className='right'>
                    <h4>About Us</h4>
                    <p>NBFire is a leader in the automation of Jupyter
                    Notebooks.  Our platforms allow you to host, run, schedule 
                    and parameterise your analytics in the cloud.  
                    Build your Jupyter Notebook data science ecosystem with NBFire</p>
                    <div className='social'>
                      
                        <a href="https://www.linkedin.com/company/nbfire/" target="_blank" rel="noopener noreferrer"><FaLinkedin size={30} style={{ color: '#FAF9F6', marginRight: '1rem' }}/></a>
                    </div>
                </div>

            </div>
        </div>
    )
}

export default Footer
