import React, { useEffect } from "react";
import { Routes, Route, useLocation, Link } from 'react-router-dom';
import Home from "./routes/Home";
import Contact from "./routes/Contact";
import Signup from "./routes/Signup";
import Documentation from "./routes/Documentation";
import ReactGA from 'react-ga4';
import { HelmetProvider } from 'react-helmet-async'
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Account from "./routes/Account";
import PrivacyPolicy from "./routes/PrivacyPolicy";
import Terms from "./routes/Terms";
import CookieConsent from "react-cookie-consent";
import DocTimezones from "./routes/DocTimezones";
import DocAccountUpgrade from "./routes/DocAccountUpgrade";
import ExampleGoogleSheets from "./routes/ExampleGoogleSheets";
import NewsPackaging from "./routes/NewsPackaging";
import HidingParameters from "./routes/HidingParameters";
import SignupWithCode from "./routes/SignupWithCode";
import SignupWithCodeAccountDetails from "./routes/SignupWithCodeAccountDetails";

const helmetContext = {};

function usePageViews() {
  let location = useLocation();
  useEffect(() => {
    if (!window.GA_INTIALIZED) {
      ReactGA.initialize("G-XQ2ZJN0NTE");
      window.GA_INTIALIZED = true;
    }
    ReactGA.send({ hitType: "pageview", page: location.pathname });

  })
}

function App() {
  usePageViews();
  return (
    <div>
      <ToastContainer
        position="top-right"
        autoClose={3000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="dark"
        id="toast_container"
      />
      {/* Same as */}
      <ToastContainer />
      <HelmetProvider context={helmetContext}>
        <Routes>
          <Route path='/' element={<Home />} />
          <Route path='/contact' element={<Contact />} />
          <Route path='/account' element={<Account />} />
          <Route path='/signup' element={<Signup />} />
          <Route path='/signup-with-code' element={<SignupWithCode />} />
          <Route path='/signup-with-code/account-details' element={<SignupWithCodeAccountDetails />} />
          <Route path='/resources' element={<Documentation />} />
          <Route path='/resources/timezones' element={<DocTimezones />} />
          <Route path='/resources/upgradeaccount' element={<DocAccountUpgrade/>} />
          <Route path='/resources/googlesheets' element={<ExampleGoogleSheets/>} />
          <Route path='/resources/packageprocessing' element={<NewsPackaging/>} />
          <Route path='/resources/hidingparameters' element={<HidingParameters />} />
          <Route path='/privacy-policy' element={<PrivacyPolicy />} />
          <Route path='/terms-and-conditions' element={<Terms />} />
          <Route path="*" element={<Home />} />
        </Routes>
      </HelmetProvider>
      <CookieConsent
        location="bottom"
        buttonText="I Understand"
        cookieName="nbfireCookieConsent"
        style={{ background: "#2B373B", padding: "1.5rem", fontSize: "20px" }}
        buttonStyle={{ color: "#4e503b", fontSize: "18px" }}
      >
        This website collects cookies to deliver better user experience as defined in our <Link style={{ textDecoration:'underline' }} to="/privacy-policy">Privacy Policy</Link> and <Link style={{ textDecoration:'underline' }} to="/terms-and-conditions">Terms and Conditions</Link>
      </CookieConsent>
    </div>
  );
}

export default App;
