import React, { Fragment } from 'react';
import Video1 from './Video1';
import Video2 from './Video2';
import { Grid, Paper, Typography } from '@mui/material';

const DocumentationDetails = () => {

    return (
        <Fragment>
            <Grid container spacing={0} sx={{ m: 0 }} >
                <Grid item xs={12} sm={12}  >
                    <Paper sx={{ padding: 2, mb: 2, pl: { sm: 2, md: 30 }, pr: { sm: 2, md: 30 }, height: '100%', bgcolor: 'black', color: 'white' }}>
                        <Typography variant="h5" sx={{ pb: 3 }}>Quickstart: How to fire your Notebooks in 3 easy steps</Typography>
                        <Typography variant="h6" sx={{ pb: 3 }}>You can download the example notebook used in these videos from <a href="https://examplenotebooks.s3.amazonaws.com/nbfiredemo.ipynb" style={{ textDecoration: "underline" }}>here</a>.  To obtain an API key to use in the Weather Notebook please go to <a href="https://www.visualcrossing.com/weather-api" target="_blank" rel="noreferrer" style={{ textDecoration: "underline" }}>visualcrossing</a>.</Typography>
                    </Paper>
                </Grid>
                <Grid item xs={12} sm={12} md={5} >
                    <Paper sx={{ padding: 2, mb: 2, pl: { sm: 2, md: 30 }, height: '100%', bgcolor: 'black', color: 'white' }}>
                        <Typography variant="h6" sx={{ pb: 3 }}>Step 1 : Prepare your notebook</Typography>
                        <Typography variant="body1" sx={{ pb: 3 }}><b>Install Packages:</b> Create a cell above any import commands and install packages using the "!pip install" command.  In the example below we install the two packages we need, Matplotlib and Pandas.</Typography>
                        <Typography variant="body1" sx={{ pb: 3 }}><b>Add Notebook Parameters:</b> NBFire uses Papermill to allow the use of parameters in the Jupyter Notebook. Using parameters makes your notebooks easy to re-use.  Create a cell near the top of your notebook and then choose View > Cell Toolbar > Tags and then enter 'parameters' as the tag for cell you created.  In the video below the example notebook takes two parameters, API_KEY and LOCATION.  We specify the parameters names in the cell and then add values for them in the next step.</Typography>
                    </Paper>
                </Grid>
                <Grid item xs={12} sm={12} md={7} >
                    <Paper sx={{ padding: 2, mb: 2, pr: { sm: 2, md: 30 }, height: '100%', bgcolor: 'black', color: 'white' }}>
                        <Video1 />
                    </Paper>
                </Grid>
                <Grid item xs={12} sm={12} md={5} >
                    <Paper sx={{ padding: 2, mb: 2, pl: { sm: 2, md: 30 }, height: '100%', bgcolor: 'black', color: 'white' }}>
                        <Typography variant="h6" sx={{ pb: 3, pt: 3 }}>Step 2 : Upload your Notebook to NBFire</Typography>
                        <Typography variant="body1" sx={{ pb: 3 }}><b>Upload Notebooks:</b> Navigate to the add notebook page and either drag and drop your ".ipynb" file or click upload to select it. Enter an optional description and press the upload button. Your notebook will then be uploaded and processed ready for automating.</Typography>
                        <Typography variant="body1" sx={{ pb: 3 }}><b>Schedule Notebook:</b> Select the tile for the uploaded notebook and use the "Configure Schedule" panel to set the required frequency and timezone. Any Notebook pramaeters can be configured here as well.  In the video below we enter a value for the API_KEY and LOCATION. Clicking on 'FIRE SCHEDULE' will submit the schedule and ensure your Notebook runs.</Typography>
                    </Paper>
                </Grid>
                <Grid item xs={12} sm={12} md={7} >
                    <Paper sx={{ padding: 2, mb: 2, pr: { sm: 2, md: 30 }, height: '100%', bgcolor: 'black', color: 'white' }}>
                        <Video2 />
                    </Paper>
                </Grid>
                <Grid item xs={12} sm={12}  >
                    <Paper sx={{ padding: 2, pl: { sm: 2, md: 30 }, pr: { sm: 2, md: 30 }, height: '100%', bgcolor: 'black', color: 'white' }}>
                        <Typography variant="h6" sx={{ pb: 3, pt: 3 }}>Step 3 : View your output notebooks</Typography>
                        <Typography variant="body1" sx={{ pb: 3 }}>Boom! You can view the progress of your run in the UI.  Once the notebook has run you can download an output notebook as a record of your analysis.</Typography>
                    </Paper>
                </Grid>
            </Grid>
        </Fragment >
    )
}

export default DocumentationDetails