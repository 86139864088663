import React, { Fragment, useCallback, useEffect, useState } from 'react';
import { Box, Button, CircularProgress, Grid, List, ListItem, ListItemIcon, ListItemText, Paper, Typography } from '@mui/material';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import axios from 'axios';

export const GET_ACCOUNT_TYPES_URL = process.env.REACT_APP_NBFIRE_URL + "/accounts";

const SelectAccountType = props => {

    const {setType} = props;

    const [accountTypes, setAccountTypes] = useState([]);

    var sortedAccountTypes = [...accountTypes];
    sortedAccountTypes.sort((a, b) => a.ordinal - b.ordinal);

    const getAccountTypes = useCallback(() => {
        axios.get(GET_ACCOUNT_TYPES_URL, {
            crossDomain: true
        }).then((response) => {
            setAccountTypes(response.data);
        });
    }, []);

    useEffect(() => {
        getAccountTypes();
    }, [getAccountTypes]);

    const gridWidth = 12 / accountTypes.length;

    return (
        <Fragment>
            { accountTypes.length === 0 ?
                <Box style={{ width: '100%', display: 'flex', justifyContent: 'center'}}>
                    <CircularProgress sx={{ color: 'white', mt: 5 }}/>
                </Box> 
                :
                <Paper sx={{ padding: 2, mb: 2, pl: { sm: 2, md: 30 }, pr: { sm: 2, md: 30 }, height: '100%', bgcolor: 'black', color: 'white' }}>
                    <Typography variant="h4" sx={{ m: 2, color: 'white' }}>Select Account Type</Typography>
                    <Grid container spacing={0} sx={{ m: 0 }}>
                        {
                            sortedAccountTypes.map(account => {
                                return (
                                    <Grid item xs={12} sm={gridWidth} key={account.displayName} sx={{p:2}}>
                                        <Paper sx={{ padding: 2, mb: 2, height: '100%', bgcolor: 'black', border: 2, borderColor: 'white', color: 'white' }}>

                                            <Typography variant="h6" sx={{ mb: 2 }}>{account.displayName}</Typography>
                                            <List dense={true} sx={{ mb: 2 }}>
                                                {
                                                    account.features.map(feature => {
                                                        return (
                                                            <ListItem key={feature}>
                                                                <ListItemIcon>
                                                                    <CheckCircleIcon sx={{ color: 'white' }} />
                                                                </ListItemIcon>
                                                                <ListItemText
                                                                    primary={feature}
                                                                />
                                                            </ListItem>
                                                        );
                                                    })
                                                }
                                            </List>
                                            <Typography variant="h6" sx={{ mb: 2 }}>{account.price}</Typography>
                                            
                                                <Button 
                                                    color="secondary" 
                                                    variant="contained" 
                                                    sx={{ width: '100%', p: 1,  "&.Mui-disabled": {background: "#c0c0c0", color: "black"} }}
                                                    onClick={() => setType(account.name)}
                                                >
                                                    Select
                                                </Button>
                                            
                                        </Paper>
                                    </Grid>);
                            })
                        }
                    </Grid >
                </Paper>
            }
        </Fragment >
    );
}

export default SelectAccountType;