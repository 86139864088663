import React, { Fragment } from 'react';
import { Image } from 'react-native';
import { Grid, Paper, Typography, useMediaQuery, useTheme } from '@mui/material';

const HidingDataDetails = () => {
    const theme = useTheme();
    const isMatch = useMediaQuery(theme.breakpoints.down('md'));
    const imageHeight = isMatch ? 150 : 250;

    return (
        <Fragment>
            <Grid container spacing={0} sx={{ m: 0 }} >
                <Grid item xs={12} sm={12}  >
                    <Paper sx={{ padding: 2, mb: 2, pl: { sm: 2, md: 30 }, pr: { sm: 2, md: 30 }, height: '100%', bgcolor: 'black', color: 'white' }}>
                        <Typography variant="h5" sx={{ pb: 3 }}>Hiding Sensitive data in Jupyter Notebooks</Typography>
                    </Paper>
                </Grid>
                <Grid item xs={12} sm={12} md={12} >
                    <Paper sx={{ padding: 2, mb: 2, pl: { sm: 2, md: 30 }, height: '100%', bgcolor: 'black', color: 'white' }}>
                        <Typography variant="body1" sx={{ pb: 3 }}>Sometimes in order to share or store a processed Jupyter Notebook a way of hiding senstive data is required.</Typography>
                        <Typography variant="body1" sx={{ pb: 3 }}>This could be account credentials or API keys which are required to access the data required for analysis, but which it is not appropriate to include in the shared analysis.</Typography>
                        <Typography variant="body1" sx={{ pb: 3 }}>The NBFire platform contains a simple way of excluding this from the processed Jupyter Notebooks to allow the sharing and storage of the processed analysis with the confidence that private data isn't included.</Typography>
                    </Paper>
                </Grid>
                <Grid item xs={12} sm={12} md={5} >
                    <Paper sx={{ padding: 2, mb: 2, pl: { sm: 2, md: 30 }, height: '100%', bgcolor: 'black', color: 'white' }}>
                        <Typography variant="body1" sx={{ pb: 3 }}><b>Parameterise Notebook:</b> NBFire supports the parameterisation of notebooks, this allows sensitive data to be provided to the notebook at runtime rather than included within it</Typography>
                        <Typography variant="body1" sx={{ pb: 3 }}><b>Add Tag to Parameters Cell:</b> To parameterise a notebook, simply add a tag called 'parameters' to the required cell as shown</Typography>
                        <Typography variant="body1" sx={{ pb: 3 }}><b>Upload Notebook:</b> Upload the notebook to NBFire and the parameters will be automatically parsed. These will be shown on the notebook tile once processing is complete</Typography>
                    </Paper>
                </Grid>
                <Grid item xs={12} sm={12} md={7} >
                    <Paper sx={{ padding: 2, mb: 2, pr: { sm: 2, md: 30 }, height: '100%', bgcolor: 'black', color: 'white' }}>
                    <Image style={{height: imageHeight }} resizeMode="contain" 
                        source={{uri: 'https://nbfire-images.s3.amazonaws.com/sensitive_data_notebook.webp',}} 
                        alt={'Jupyter Notebook with sensitive data parameters'}
                    />
                    </Paper>
                </Grid>
                <Grid item xs={12} sm={12} md={7} >
                    <Paper sx={{ padding: 2, mb: 2, pl: { sm: 2, md: 30 }, height: '100%', bgcolor: 'black', color: 'white' }}>
                        <Typography variant="body1" sx={{ pb: 3 }}><b>Select Notebook:</b> Click the notebook tile to navigate to the details page where the options to run and schedule the notebook will be available</Typography>
                        <Typography variant="body1" sx={{ pb: 3 }}><b>Select Hide Parameters:</b> On either the schdule or run notebook sections ensure the 'Hide Parameters' option is selected</Typography>
                        <Typography variant="body1" sx={{ pb: 3 }}><b>Enter Sensitive data:</b> The parameter name will be available to enter the sensitive data as shown</Typography>
                    </Paper>
                </Grid>
                <Grid item xs={12} sm={12} md={5} >
                    <Paper sx={{ padding: 2, mb: 2, pr: { sm: 2, md: 30 }, height: '100%', bgcolor: 'black', color: 'white' }}>
                    <Image style={{height: 250 }} resizeMode="contain" 
                        source={{uri: 'https://nbfire-images.s3.amazonaws.com/configure_hide_parameters.webp',}} 
                        alt={'Configure Jupyter Notebook run to hide sensitive parameters'}
                    />
                    </Paper>
                </Grid>
                <Grid item xs={12} sm={12} md={5} >
                    <Paper sx={{ padding: 2, mb: 2, pl: { sm: 2, md: 30 }, height: '100%', bgcolor: 'black', color: 'white' }}>
                        <Typography variant="body1" sx={{ pb: 3 }}><b>Fire or Schedule Notebook:</b> Click the 'Fire Schedule' or 'Fire Notebook' button. This will either enable an active schedule or run the notebook instantly</Typography>
                        <Typography variant="body1" sx={{ pb: 3 }}><b>Download the Processed Notebook:</b> Once the Jupyter Notebook has finished processing it can be downloaded. As shown the injected parameter will not be displayed in the output</Typography>
                        <Typography variant="body1" sx={{ pb: 3 }}><b>Share the Analysis:</b> The processed notebook can now be shared or stored with the confidence that the private and sensitive data has not been included!</Typography>
                    </Paper>
                </Grid>
                <Grid item xs={12} sm={12} md={7} >
                    <Paper sx={{ padding: 2, mb: 2, pr: { sm: 2, md: 30 }, height: '100%', bgcolor: 'black', color: 'white' }}>
                    <Image style={{height: imageHeight }} resizeMode="contain" 
                        source={{uri: 'https://nbfire-images.s3.amazonaws.com/sensitive_data_output.webp',}} 
                        alt={'Processed Jupyter Notebook with sensitive data removed'}
                    />
                    </Paper>
                </Grid>
            </Grid>
        </Fragment >
    )
}

export default HidingDataDetails