
import React from 'react'
import Vimeo from '@u-wave/react-vimeo';
import './Video1Styles.css'


const Video2 = () => {
    return (
     <div className='videowidth'>
        <div>
            <Vimeo
                video="781803614"
                responsive
                muted={true}
            />
        </div>
    </div>
       
    )
}

export default Video2