import React, { Fragment, useCallback, useEffect, useState } from 'react';
import { loadStripe } from '@stripe/stripe-js';
import { Box, Button, CircularProgress, Grid, List, ListItem, ListItemIcon, ListItemText, Paper, Typography } from '@mui/material';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { Auth } from 'aws-amplify';
import axios from 'axios';
import { GetCognitoToken } from '../utils/TokenUtils';

export const GET_ACCOUNT_TYPES_URL = process.env.REACT_APP_NBFIRE_URL + "/accounts";
export const UPDATE_USER_URL = process.env.REACT_APP_NBFIRE_URL + "/user";

const AccountTypeSelection = params => {

    const { callback, accountType, isEditMode, hideFreeAccount=false } = params;

    const [userData, setUserData] = useState();
    const [accountTypes, setAccountTypes] = useState([]);

    var sortedAccountTypes = [...accountTypes];
    sortedAccountTypes.sort((a, b) => a.ordinal - b.ordinal);

    useEffect(() => {
        Auth.currentAuthenticatedUser().then(userData => {
            setUserData(userData);
        }).catch(() => {
            setUserData(undefined);
        });
    }, []);

    const getAccountTypes = useCallback(() => {
        axios.get(GET_ACCOUNT_TYPES_URL, {
            crossDomain: true
        }).then((response) => {
            var processedAccounts = response.data.filter((accountType) => !(hideFreeAccount && accountType.name === "FREE"));
            setAccountTypes(processedAccounts);
        });
    }, [hideFreeAccount]);

    useEffect(() => {
        getAccountTypes();
    }, [getAccountTypes]);

    const buildRequestData = (account) => {
        const requestData = {
            account_type: account.name
        };
        return requestData;
    };

    const createStripeSubscription = async (account) => {
        const stripe = await loadStripe(account.stripeAppKey)
        await stripe.redirectToCheckout({
            lineItems: [{
                price: account.stripeSubKey,
                quantity: 1
            }],
            customerEmail: userData.attributes.email,
            mode: 'subscription',
            successUrl: window.location.protocol + "//" + window.location.host + "/account",
            cancelUrl: window.location.protocol + "//" + window.location.host + "/account"
        })
    }

    const handleClickSubscribe = async (event, account) => {
        GetCognitoToken().then(jwt => {
            axios.post(UPDATE_USER_URL, buildRequestData(account), {
                headers: { "Authorization": `Bearer ${jwt}` },
                crossDomain: true
            }).then(response => {
                if(account.name === "STANDARD" || account.name === "PROFESSIONAL"){
                    console.log(account);
                    createStripeSubscription(account);
                } else if(callback) {
                    callback();
                }
            }).catch(error => {
                console.error(`Error updating user details for ${userData.attributes.email}`);
            });
        });
    }

    const gridWidth = 12 / accountTypes.length;

    return (
        <Fragment>
            { accountTypes.length === 0 ?
                <Box style={{ width: '100%', display: 'flex', justifyContent: 'center'}}>
                    <CircularProgress sx={{ color: 'white', mt: 5 }}/>
                </Box> 
                :
                <Fragment>
                    <Typography variant="h4" sx={{ mb: 2, color: 'white' }}>Select Account Type</Typography>
                    <Grid container spacing={0} sx={{ m: 0 }}>
                        {
                            sortedAccountTypes.map(account => {
                                return (
                                    <Grid item xs={12} sm={gridWidth} key={account.displayName} sx={{p:2}}>
                                        <Paper sx={{ padding: 2, mb: 2, height: '100%', bgcolor: 'black', border: 2, borderColor: 'white', color: 'white' }}>

                                            <Typography variant="h6" sx={{ mb: 2 }}>{account.displayName}</Typography>
                                            <List dense={true} sx={{ mb: 2 }}>
                                                {
                                                    account.features.map(feature => {
                                                        return (
                                                            <ListItem key={feature}>
                                                                <ListItemIcon>
                                                                    <CheckCircleIcon sx={{ color: 'white' }} />
                                                                </ListItemIcon>
                                                                <ListItemText
                                                                    primary={feature}
                                                                />
                                                            </ListItem>
                                                        );
                                                    })
                                                }
                                            </List>
                                            <Typography variant="h6" sx={{ mb: 2 }}>{account.price}</Typography>
                                            
                                                <Button 
                                                    disabled={accountType && accountType === account.name} 
                                                    color="secondary" 
                                                    variant="contained" 
                                                    sx={{ width: '100%', p: 1,  "&.Mui-disabled": {background: "#c0c0c0", color: "black"} }} 
                                                    onClick={event => { handleClickSubscribe(event, account) }}
                                                >
                                                    {isEditMode? `Switch to ${account.displayName}` : "Select"}
                                                </Button>
                                            
                                        </Paper>
                                    </Grid>);
                            })
                        }
                    </Grid >
                </Fragment>
            }
        </Fragment >
    );
}

export default AccountTypeSelection;