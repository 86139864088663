
import { Alert, Box, Button, Grid, Paper, TextField, Typography } from '@mui/material';
import React, { Fragment, useState } from 'react'
import { FormatTimestamp } from '../utils/DateUtils';
import moment from 'moment';
import { GetCognitoToken } from '../utils/TokenUtils';
import axios from 'axios';
import { Link } from 'react-router-dom';

const SUBSCRIPTION_DATE_FORMAT = 'YYYYMMDD';
const DISPLAY_DATE_FORMAT = 'MMMM Do YYYY';

export const UPDATE_USER_CODE_URL = process.env.REACT_APP_NBFIRE_URL + "/user";

const accountDisplay = {
    FREE: "Trial",
    STANDARD: "Tier 1",
    PROFESSIONAL: "Tier 2",
    ADMIN: "Admin",
    TEST: "Test Account"
}

const linkStyle = {
    textDecoration: "underline",
    color: 'black'
};

const UserDetails = props => {
    const {userDetails, setRefreshUserDetails, verifyCode = false} = props;
    const subscriptionPeriodStart = userDetails.subscription_period_start ? moment(userDetails.subscription_period_start, SUBSCRIPTION_DATE_FORMAT).format(DISPLAY_DATE_FORMAT) : "" ;
    const subscriptionPeriodEnd = userDetails.subscription_period_start ? moment(userDetails.subscription_period_end, SUBSCRIPTION_DATE_FORMAT).format(DISPLAY_DATE_FORMAT) : "" ;
    const accountCreatedAt = userDetails.createdAt ? FormatTimestamp(userDetails.createdAt) : "";
    const accountStatus = userDetails.subscription_active !== undefined ? userDetails.subscription_active ? "Active" : "Disabled" : "";

    const [accountCode, setAccountCode] = useState("");

    const updateAccountCode = event => {
        setAccountCode(event.target.value);
    }

    const buildRequestData = () => {
        const requestData = {
            account_code: accountCode,
            account_type: "STANDARD"
        };
        return requestData;
    };

    const handleSubmitCode = (event) => {
        event.preventDefault();
        GetCognitoToken().then(jwt =>{
            axios.post(UPDATE_USER_CODE_URL, buildRequestData(), {
                headers: {"Authorization" : `Bearer ${jwt}`},
                crossDomain: true
            }).then(response => {
                setRefreshUserDetails(true);
            }).catch(error => {
                console.error(`Error applying code`);
            });
        });
    };

    return (
        <Fragment>
            <Grid container spacing={0} sx={{ m: 0 }} >
                <Grid item xs={12} sm={12}  >
                    <Paper sx={{ padding: 2, pl: { sm: 2, md: 30 }, pr: { sm: 2, md: 30 }, height: '100%', bgcolor: 'black', color: 'white' }}>
                        <Typography variant="h5" sx={{ ml: 2, mt: 2, color: 'white' }}>Account Details</Typography>
                    </Paper>
                </Grid>

                <Grid item xs={12} sm={12} >
                    <Paper sx={{ padding: 2, pl: { sm: 2, md: 30 }, pr: { sm: 2, md: 30 }, pt: 0, pb: 0, height: '100%', bgcolor: 'black', color: 'white' }}>
                        <Grid container spacing={0} sx={{ p: 2 }}>
                            <Grid item xs={12} sm={4}>
                                <Typography variant="body1" sx={{ mb: 1, color: 'white' }}>Email: {userDetails.id || ""}</Typography>
                                <Typography variant="body1" sx={{ mb: 1, color: 'white' }}>Account Created: {accountCreatedAt}</Typography>
                                <Typography variant="body1" sx={{ mb: 1, color: 'white' }}>Account Status: {accountStatus}</Typography>
                            </Grid>
                            <Grid item xs={12} sm={4}>
                                <Typography variant="body1" sx={{ mb: 1, color: 'white' }}>Subscription Type: {userDetails.account_type ? accountDisplay[userDetails.account_type] : ""}</Typography>
                                { !userDetails.lifetime_sub &&
                                    <Fragment>
                                        { (userDetails.account_type === "STANDARD" || userDetails.account_type === "PROFESSIONAL") && <Typography variant="body1" sx={{ mb: 1, color: 'white' }}>Subscription Period Start Date: {subscriptionPeriodStart}</Typography>}
                                        { (userDetails.account_type === "STANDARD" || userDetails.account_type === "PROFESSIONAL") && <Typography variant="body1" sx={{ mb: 1, color: 'white' }}>Subscription Period End Date: {subscriptionPeriodEnd}</Typography>}
                                    </Fragment>
                                }
                                { userDetails.lifetime_sub && <Typography variant="body1" sx={{ mb: 1, color: 'white' }}>Lifetime Account: {userDetails.lifetime_sub ? "True" : "False"}</Typography>}
                                { userDetails.account_code && <Typography variant="body1" sx={{ mb: 1, color: 'white' }}>Account Code: {userDetails.account_code}</Typography>}
                            </Grid>
                            <Grid item xs={12} sm={4}> 
                                {props.children}
                            </Grid>
                        </Grid>
                    </Paper>
                </Grid>
                {verifyCode && 
                    <Grid item xs={12} sm={12} >
                        <Paper sx={{ padding: 2, pl: { sm: 2, md: 30 }, pr: { sm: 2, md: 30 }, pt: 0, pb: 0, height: '100%', bgcolor: 'black', color: 'white' }}>
                            {userDetails.lifetime_sub && userDetails.account_code && userDetails.account_type === "STANDARD" &&
                                <Box style={{ width: '100%', display: 'flex', justifyContent: 'center'}}>
                                    <Alert sx={{mt: 1, mb: 1}} severity="success">Account code applied successfully</Alert>
                                </Box>
                            } 
                            {userDetails.account_code && !userDetails.lifetime_sub &&
                                <Box style={{ width: '100%', display: 'flex', justifyContent: 'center'}}>
                                    <Alert sx={{mt: 1, mb: 1}} severity="error">Account code is invalid or already redeemed, try again or <Link style={linkStyle} to="/contact">Contact Support</Link></Alert>
                                </Box>
                            }
                            { userDetails.account_type === "FREE" &&
                                <form onSubmit={handleSubmitCode}>
                                    <Grid item xs={12}>
                                        <TextField
                                            id="account-code"
                                            name="accountCode"
                                            label="Account Code"
                                            type="text"
                                            value={accountCode}
                                            onChange={updateAccountCode}
                                            sx={{ width: '100%', m: 2, '& .MuiInputBase-input': {backgroundColor: '#1A2027', borderRadius: 1}, '& label.Mui-focused': {color: 'white'}, '& label': {color: 'white' }, '& .MuiOutlinedInput-root': { '& input': {color: 'white'}} }}
                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Button variant="contained" color="secondary" type="submit" sx={{ ml: 2, mb: 2 }}>
                                            Submit Code
                                        </Button>
                                    </Grid>
                                </form>
                            }
                        </Paper>
                    </Grid>
                }
            </Grid>
        </Fragment>
    );
}

export default UserDetails