
import React, { Fragment, useCallback, useEffect, useState } from 'react'
import { Amplify } from 'aws-amplify';
import { Auth } from 'aws-amplify';
import { Authenticator, CheckboxField, TextField, useAuthenticator } from '@aws-amplify/ui-react';
import '@aws-amplify/ui-react/styles.css';
import { Hub } from 'aws-amplify';
import './Account.css'
import AccountTypeSelection from './AccountTypeSelection';
import { Button, Paper, Typography } from '@mui/material';
import axios from 'axios';
import { GetCognitoToken } from '../utils/TokenUtils';
import UserDetails from './UserDetails';
import InitialiseAccount from './InitialiseAccount';
import { Link } from 'react-router-dom';

export const GET_USER_URL = process.env.REACT_APP_NBFIRE_URL + "/user";

Amplify.configure({
    Auth: {
        region: process.env.REACT_APP_COGNITO_REGION,
        userPoolId: process.env.REACT_APP_COGNITO_USER_POOL_ID,
        userPoolWebClientId: process.env.REACT_APP_COGNITO_USER_POOL_WEB_CLIENT_ID,
    },
});

const formFields = {
    signIn: {
        username: {
            placeholder: 'Email',
        },
    },
    signUp: {
        name: {
            order:1,
            isRequired: true
        },
        username: {
            placeholder: 'Email',
        }
    },
    resetPassword: {
        username: {
            placeholder: 'Email:',
        },
    },
};

const UserAccount = params => {
    const { type = "signUp", signUpType = "FREE", verifyCode = false } = params;

    const [userDetails, setUserDetails] = useState();
    const [editAccount, setEditAccount] = useState(false);
    const [refreshUserDetails, setRefreshUserDetails] = useState(false);

    const signUserOut = () => {
        Auth.signOut();
    }

    const getUserDetails = useCallback(() => {
        GetCognitoToken().then(jwt => {
            axios.get(GET_USER_URL, {
                headers: { "Authorization": `Bearer ${jwt}` },
                crossDomain: true
            }).then((response) => {
                setUserDetails(response.data);
                setEditAccount(false);
            });
        });
    }, []);    

    useEffect(() => {
        if(type !== "signUp" || refreshUserDetails){
            getUserDetails();
            setRefreshUserDetails(false);
        }
    }, [getUserDetails, refreshUserDetails, type]);

    const userEventListener = (data) => {

        switch (data.payload.event) {
            case 'signIn':
                getUserDetails();
                break;
            case 'signUp':
                getUserDetails();
                break;
            case 'signOut':
                setUserDetails(undefined);
                break;
            case 'signIn_failure':
                setUserDetails(undefined);
                break;
            default:
                // NO-OP
                break;
        }
    }

    Hub.listen('auth', userEventListener);

    return (
        <div className="signup-box">
            <Authenticator 
                initialState={type} 
                formFields={formFields}
                signUpAttributes={['name']}
                components={{
                    SignUp: {
                      FormFields() {
                        const { validationErrors } = useAuthenticator();
            
                        return (
                          <>
                            {/* Re-use default `Authenticator.SignUp.FormFields` */}
                            <Authenticator.SignUp.FormFields />

                            {/* If siging up with a code an additional field is required */
                            signUpType==="WITH_CODE" &&
                                <TextField
                                name="custom:account_code"
                                label="Account Code"
                                placeholder="Insert Code"
                                type="text"
                                isRequired='true'
                                />                            
                            }
            
                            {/* Append & require Terms and Conditions field to sign up  */}
                            <CheckboxField
                              errorMessage={validationErrors.acknowledgement}
                              hasError={!!validationErrors.acknowledgement}
                              name="acknowledgement"
                              value="yes"
                              label={
                                <div>
                                   <span>I accept the </span>
                                   <Link style={{color: "black", textDecoration:'underline'}} to={'/terms-and-conditions'} target="_blank" rel="noopener noreferrer" >terms and conditions</Link>
                                   <span> and </span>
                                   <Link style={{color: "black", textDecoration:'underline'}} to={'/privacy-policy'} target="_blank" rel="noopener noreferrer" >privacy policy</Link>
                                </div>
                                }
                            />
                            <CheckboxField
                              name="custom:email_updates"
                              value="yes"
                              label={
                                <div>
                                   <span>I would like to receive email updates from NBFire</span>
                                </div>
                                }
                            />
                          </>
                        );
                      },
                    },
                  }}
                  services={{
                    async validateCustomSignUp(formData) {
                      if (!formData.acknowledgement) {
                        return {
                          acknowledgement: 'You must agree to the Terms and Conditions',
                        };
                      } 
                    },
                  }}
            >
            
                {() => (
                    <div>
                        { type === "signUp" && signUpType ?
                            <InitialiseAccount signUpType={signUpType} callback={getUserDetails}/>
                        :
                            <Fragment>
                                {userDetails ?
                                    <Fragment>
                                        {userDetails.account_type && !editAccount ?
                                            <UserDetails userDetails={userDetails} verifyCode={verifyCode} setRefreshUserDetails={setRefreshUserDetails}/>
                                            :
                                            <Paper sx={{ padding: 2, pl: { sm: 2, md: 30 }, pr: { sm: 2, md: 30 }, height: '100%', bgcolor: 'black', color: 'white' }}>
                                                <AccountTypeSelection callback={getUserDetails} accountType={userDetails.account_type} isEditMode={editAccount} hideFreeAccount={true}/>
                                            </Paper>
                                        }
                                        <Paper sx={{ padding: 2, pl: { sm: 2, md: 30 }, pr: { sm: 2, md: 30 }, height: '100%', bgcolor: 'black', color: 'white' }}>
                                            {!verifyCode && userDetails.account_type && userDetails.account_type === "FREE" && !editAccount && <Button color="secondary" variant="contained" onClick={()=>{setEditAccount(true)}} sx={{ ml: 2 }}>Upgrade Account</Button>}
                                            {editAccount && <Button color="secondary" variant="contained" onClick={() => setEditAccount(false)} sx={{ ml: 2 }}>Return To Account Details</Button>}
                                            {!verifyCode && <Button color="secondary" variant="contained" onClick={signUserOut} sx={{ ml: 2 }}>Sign Out</Button>}
                                        </Paper>
                                    </Fragment>
                                    :
                                    <Fragment>
                                        <Typography variant="h6" sx={{ mb: 2, color: 'white' }}>loading...</Typography>
                                    </Fragment>
                                }
                            </Fragment>
                        }
                    </div>
                )}
            </Authenticator>
        </div>
    )
}

export default UserAccount