import React from 'react';
import { Helmet } from 'react-helmet';
import Footer from '../components/general/Footer';
import HeroImage from '../components/general/HeroImage';
import Navbar from '../components/general/Navbar';
import DocLinks from '../components/documentation/DocLinks';
import HidingDataDetails from '../components/documentation/HidingDataDetails';

const HidingParameters = () => {
    return (
        <div>
            <Helmet>
                <title>NBFire | Resources | Hiding Sensitive Data</title>
                <meta
                    name="description"
                    content="How to hide sensitive data in Jupyter Notebooks"
                />
                <meta
                    name="keywords"
                    content="Jupyter Notebooks hide sensitive data, Jupyter hide sensitive parameters, jupyter share notebook hide data"
                />
            </Helmet>
            <Navbar />
            <HeroImage heading='Resources' />
            <DocLinks/>
            <HidingDataDetails />
            <Footer />
        </div>
    )
}

export default HidingParameters