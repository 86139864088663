import React, { Fragment } from 'react';
import { List, ListItem, ListItemText, Paper, Typography } from '@mui/material';

const PrivacyContent = () => {

    return (
        <Fragment>
            <Paper sx={{ padding: 2, mb: 2, pl: { sm: 2, md: 30 }, pr: { sm: 2, md: 30 }, height: '100%', bgcolor: 'black', color: 'white' }}>
                <Typography variant="h6">The privacy policy (“the policy”) of the nbfire.io platform (the “Website”) governs the way we collect personal data, which we may collect through various channels. The policy sets out how the website handles any questions or concerns regarding how we use and disclose personal information when you reach us.</Typography>
                <List sx={{ listStyle: "decimal" }}>
                    <ListItem sx={{ display: "list-item" }}>
                        <ListItemText primary='WHAT WE MEAN BY PERSONAL INFORMATION. The use of our website may cause us to automatically gather Personal Information about you directly from you and/or from any third party. We will let you know as to why you must give us your personal information as required by law, as well as the ramifications thereof, for not doing so. Any information pertaining to an identified or recognisable individual, which is referred to as "Personal Information" in this policy is discussed below:' />
                        <List sx={{ listStyle: "decimal", ml: 2, mr: 2 }}>
                            <ListItem sx={{ display: "list-item" }}>
                                <ListItemText primary="Contact Details: We may collect professional contact information, such as, first and family name, email address and any other pertinent information such as, your employment details including company information, from employees and any other persons connected with our business customers, prospects, and partners" />
                            </ListItem>
                            <ListItem sx={{ display: "list-item" }}>
                                <ListItemText primary="Information Exchange: You might give us communication information mentioned above and the specifics of your message, including the date, time, and subject line, when you get in touch with us via phone, email or any other method" />
                            </ListItem>
                        </List>
                    </ListItem>
                    <ListItem sx={{ display: "list-item" }}>
                        <ListItemText primary="WHAT WE MEAN BY PERSONAL INFORMATION COLLECTION VIA DIFFERENT SOURCES" />
                        <List sx={{ listStyle: "decimal", ml: 2, mr: 2 }}>
                            <ListItem sx={{ display: "list-item" }}>
                                <ListItemText primary='Via Website usage: You may automatically provide us with information about your visit when you use our website, including cookies, beacons, invisible tags, and other similar technologies (collectively, "cookies") in your browser and in our emails. Among the Personal Information that may be contained in this data are your IP address, web browser, device type, time and date of your visit, as well as the links you clicked. The data that may be gathered automatically are user count; daily/weekly/monthly active users. If a free user decides to upgrade to a paid membership, they will have the choice to cancel. The only purposes for which this data is collected are to improve and better the NBFire platform. Further information about how we use cookies can be found below under section Our Approach to Personal Information.' />
                            </ListItem>
                            <ListItem sx={{ display: "list-item" }}>
                                <ListItemText primary="Via Third parties: Other than our website, we may also acquire information including personal information from third parties and sources like our business partners and clients. We will consider the consolidated information as Personal Information in accordance with this Policy" />
                            </ListItem>
                        </List>
                    </ListItem>
                    <ListItem sx={{ display: "list-item" }}>
                        <ListItemText primary="OUR APPROACH TO PERSONAL INFORMATION" />
                        <List sx={{ listStyle: "decimal", ml: 2, mr: 2 }}>
                            <ListItem sx={{ display: "list-item" }}>
                                <ListItemText primary="Assistance. In order to provide technical help, including identifying and addressing any issues you report, we use personal information." />
                            </ListItem>
                            <ListItem sx={{ display: "list-item" }}>
                                <ListItemText primary="Managing relationships with customers. For the purpose of maintaining client relationships, we could process your personal information." />
                            </ListItem>
                            <ListItem sx={{ display: "list-item" }}>
                                <ListItemText primary="Interactions with you. Your email address and other Personal Information may be used to get in touch with you when it's essential to maintain your account, deliver requested information, or reply to comments and inquiries." />
                            </ListItem>
                            <ListItem sx={{ display: "list-item" }}>
                                <ListItemText primary="Recognising use patterns and enhancing the website. We utilise the information we gather from the website in order to better understand and evaluate our users' usage patterns and preferences with a view to enhance the website, through creation of new services, features, and functionalities." />
                            </ListItem>
                            <ListItem sx={{ display: "list-item" }}>
                                <ListItemText primary="Marketing. Your account information and certain other Personal Information could be used by us to send you marketing messages, such as news about sales and occasions involving and services we provide." />
                            </ListItem>
                            <ListItem sx={{ display: "list-item" }}>
                                <ListItemText primary="Administrative and legal considerations. In order to handle any administrative or legal problems with NBFire, such as, intellectual property violations, defamation, or violations of your privacy rights, we could utilise your personal information." />
                            </ListItem>
                        </List>
                    </ListItem>
                    <ListItem sx={{ display: "list-item" }}>
                        <ListItemText primary="COOKIES TO ACQUIRE PERSONAL INFORMATION" />
                        <List sx={{ listStyle: "decimal", ml: 2, mr: 2 }}>
                            <ListItem sx={{ display: "list-item" }}>
                                <ListItemText primary="Functional Cookies. For our website to be accessible to you, some cookies are absolutely essential. For instance, to keep a record of your preferences. We cannot serve you with the Website unless you accept these cookies." />
                            </ListItem>
                            <ListItem sx={{ display: "list-item" }}>
                                <ListItemText primary="Cookies for analytics. Cookies are also used by us to run, maintain, and enhance the Website through website analytics. We gather and analyse some analytics data on our behalf using Google Analytics. Our understanding of your interactions with the Website is aided by Google Analytics which may also gather data on the websites, mobile applications, and other online resources you use." />
                            </ListItem>
                            <ListItem sx={{ display: "list-item" }}>
                                <ListItemText primary="We get your consent to use cookies whenever needed by relevant laws. More information about your rights and options, as well as how to opt out of the usage, may be found under Rights and choices of the users." />
                            </ListItem>
                        </List>
                    </ListItem>
                    <ListItem sx={{ display: "list-item" }}>
                        <ListItemText primary="USER RIGHTS AND COOKIE SELECTIONS:In terms of the use of cookies and similar technologies, you have the following options." />
                        <List sx={{ listStyle: "decimal", ml: 2, mr: 2 }}>
                            <ListItem sx={{ display: "list-item" }}>
                                <ListItemText primary="Browser's options. You can control your cookie options in several web browsers. Your browser can be configured to accept cookies or to remove certain cookies. Similar to how you can control cookies using the choices of your browser, you might be able to control other technologies. Please be aware that blocking cookies may make it more difficult to utilise our website." />
                            </ListItem>
                            <ListItem sx={{ display: "list-item" }}>
                                <ListItemText>
                                    Google Analytics. You may visit <a href="https://www.google.com/policies/privacy/partners/">https://www.google.com/policies/privacy/partners/</a> to discover more about Googles policies and disable Google Analytics by installing the browser add-on found at <a href="https://tools.google.com/dlpage/gaoptout">https://tools.google.com/dlpage/gaoptout</a>, as well as through the Ad Settings for mobile applications or Google's Ads Settings.
                                </ListItemText>
                            </ListItem>
                        </List>
                    </ListItem>
                    <ListItem sx={{ display: "list-item" }}>
                        <ListItemText primary="RETENTION: If your personal information is no longer required for our processing , we take steps to delete it or to store it in a way that makes it impossible to identify you, unless we are legally obligated to retain it for a longer period of time. The type of service you received, the nature and duration of our connection with you, as well as any necessary retention periods mandated by law and the applicable statute of limitations, are just a few factors we consider when calculating the precise retention period." />
                    </ListItem>
                    <ListItem sx={{ display: "list-item" }}>
                        <ListItemText primary="SECURITY OF INFORMATION: To increase the accuracy and security of the Personal Information we collect and keep, we employ a number of administrative, technological, and physical security measures. Nevertheless, you communicate any information to us at your own risk since we cannot guarantee or assure the security of that information." />
                    </ListItem>
                    <ListItem sx={{ display: "list-item" }}>
                        <ListItemText primary="OCCASION OF AMENDMENTS TO THE PRIVACY POLICIES: As our Policy is subject to necessary amendments from time to time, we request you to check back frequently to be informed of any changes in our policy. If we update this policy, we'll make a note of the date of the most recent update and make it visible on our website nbfire.io. We shall use commercially reasonable efforts to notify you of any substantial changes that affect your rights or duties under this agreement. If we have your email address, we might send you a message, or another similar notification when you use the Website for the first time following the implementation of such major changes. Your continued use of the Website following the implementation of the amended Policy signifies that you have read, comprehended, and have accepted this Policy in its current form." />
                    </ListItem>
                </List>
            </Paper>
        </Fragment>
    )
}

export default PrivacyContent