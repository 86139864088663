import React, {useState} from 'react';
import {Link} from 'react-router-dom';
import {FaBars, FaTimes}from 'react-icons/fa';
import LoginIcon from '@mui/icons-material/Login';
import { Button } from '@mui/material';
import './NavbarStyles.css';


const
 NavBar = () => {
    const[click, setClick] = useState(false)
    const handleClick = () => setClick(!click)
    
    const [color] = useState(false)
   

  return (
    <div className={color ? 'header header-bg' : 'header'}>
        <a href="/">
        <img src="https://nbfire-images.s3.amazonaws.com/whitelogonavbar.png" alt="NBFire Logo"/>
        </a>
        <ul className={click ? 'nav-menu active' : 'nav-menu'}>
            <li class="nb-header">
                <a class="nb-header" href="/signup" id="user_signin_link" type="login">Get Started</a>
            </li>
            <li class="nb-header">
                <Link class="nb-header" to="/resources">Resources</Link>
            </li>
            <li class="nb-header">
                <Link class="nb-header" to="/contact">Contact</Link>
            </li>
            <li class="nb-header">
                <a class="nb-header" href="/account" id="user_signin_link" type="login">Account</a>
            </li>
            <li>
                <Button color="secondary" variant="contained" sx={{ fontWeight: 'bold' }} href="https://app.nbfire.io">
                    <LoginIcon sx={{ mr: {xs: 1, sm: 1, md: 0}, pr: {xs: 0, sm: 0, md: 1} }}/>Log in
                </Button>
            </li>
          
        </ul>
        <div className='hamburger' onClick={handleClick}>
            {click ? (<FaTimes />) : ( <FaBars size={30} style={{color: '#F8F0E3'}} />)}
        </div>
    </div>
  )
}

export default 
NavBar