import { Box, CircularProgress } from '@mui/material';
import React, { Fragment, useCallback, useEffect } from 'react';
import { loadStripe } from '@stripe/stripe-js';
import axios from 'axios';
import { GetCognitoToken } from '../utils/TokenUtils';
import { Auth } from 'aws-amplify';

export const GET_USER_URL = process.env.REACT_APP_NBFIRE_URL + "/user";
export const GET_ACCOUNT_TYPES_URL = process.env.REACT_APP_NBFIRE_URL + "/accounts";
export const UPDATE_USER_URL = process.env.REACT_APP_NBFIRE_URL + "/user";

const InitialiseAccount = params => {
    const { signUpType = "FREE" } = params;

    const createStripeSubscription = async (account, userData) => {
        const stripe = await loadStripe(account.stripeAppKey)
        await stripe.redirectToCheckout({
            lineItems: [{
                price: account.stripeSubKey,
                quantity: 1
            }],
            customerEmail: userData.attributes.email,
            mode: 'subscription',
            successUrl: "https://app.nbfire.io",
            cancelUrl: window.location.protocol + "//" + window.location.host + "/account"
        })
    }

    const initialiseAccount = useCallback(() => {
        axios.get(GET_ACCOUNT_TYPES_URL, {
            crossDomain: true
        }).then((response) => {
            if(signUpType !== "WITH_CODE") {
                const account = response.data.find((element) => element.name === signUpType);
                Auth.currentAuthenticatedUser().then(userDataResponse => {
                    const userData = userDataResponse;

                    GetCognitoToken().then(jwt => {
                        axios.post(UPDATE_USER_URL, {account_type: signUpType}, {
                            headers: { "Authorization": `Bearer ${jwt}` },
                            crossDomain: true
                        }).then(response => {
                            console.log(`account ${account}`);
                            if(signUpType === "STANDARD" || signUpType === "PROFESSIONAL"){
                                createStripeSubscription(account, userData);
                            } else {
                                window.location.href = "https://app.nbfire.io";
                            }
                        }).catch(error => {
                            console.error(`Error updating user details for ${userData.attributes.email}`);
                        });
                    });                
                });
            } else {
                Auth.currentAuthenticatedUser().then(() => {
                    window.location.pathname = "/signup-with-code/account-details";
                });
            }
        });
    }, [signUpType]);

    useEffect(() => {
        initialiseAccount();
    }, [initialiseAccount]);

    return (
        <Fragment>
            <Box style={{ width: '100%', display: 'flex', justifyContent: 'center'}}>
                <CircularProgress sx={{ color: 'white', mt: 5 }}/>
            </Box> 
        </Fragment>
    )
}

export default InitialiseAccount