import React, { Fragment } from 'react';
import { Accordion, AccordionDetails, AccordionSummary, Box, Grid, Paper, Typography, useMediaQuery, useTheme } from '@mui/material';
import GetStartedButton from './GetStartedButton';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Link } from 'react-router-dom';
import { Image } from 'react-native';


const linkStyle = {
  textDecoration: "underline",
  color: 'black'
};

const HomeContent = () => {
    const theme = useTheme();
    const isMatch = useMediaQuery(theme.breakpoints.down('md'));
    const imageHeight = isMatch ? 250 : 400;
    const imageHeightWide = isMatch ? 200 : 400;
  
  return (
    <Fragment>
        <Grid container spacing={0} sx={{ m: 0 }} >
            <Grid item xs={12} sm={12} md={5} sx={{p:2}} >
                <Paper sx={{ padding: 2, mb: 2, pl: { sm: 2, md: 30 }, height: '100%', bgcolor: 'black', color: 'white' }}>
                    <Box display="flex" justifyContent="center">
                        <Typography variant="h4" sx={{ mb: 2}}>Automate your Jupyter Notebooks in the cloud and spend more time exploring your data.</Typography>
                    </Box>
                    <Box display="flex" justifyContent="center">
                        <Typography variant="h6" sx={{ mb: 2, pl: 4, pr: 4 }}>NBFire is a tool created for data scientists by a team of cyber security experts.</Typography>
                    </Box>
                    <Box display="flex" justifyContent="center">
                        <GetStartedButton/>
                    </Box>                     
                </Paper>
            </Grid>
            <Grid item xs={12} sm={12} md={7} sx={{p:2}} >
                <Paper sx={{ padding: 2, mb: 2, height: '100%', bgcolor: 'black', color: 'white' }}>
                    <Image style={{height: imageHeightWide}} resizeMode="contain" 
                        source={{uri: 'https://nbfire-images.s3.amazonaws.com/NBFireScreen.webp',}} 
                        alt={'NBFire Application'}
                    />
                </Paper>
            </Grid>
            <Grid item xs={12} sx={{p:2}} >
                <Paper sx={{ padding: 2, mb: 2, pl: { sm: 2, md: 30 }, pr: { sm: 2, md: 30 }, height: '100%', bgcolor: '#607d8b', color: 'black' }}>
                    <Box display="flex" justifyContent="center">
                        <Typography variant="h4" sx={{ mb: 2, color:'black'}}>Your time is valuable. Spend it uncovering the stories within your data and not relentlessly running manual reports.</Typography>
                    </Box>
                    <Box display="flex" justifyContent="center">
                        <Typography variant="h6" sx={{ mb: 2, pl: 4, pr: 4 }}>NBFire allows you to host, run, schedule, and parametrise your analytics in the cloud.</Typography>
                    </Box>
                    <Box display="flex" justifyContent="center">
                        <GetStartedButton/>
                    </Box>                     
                </Paper>
            </Grid>
            <Grid item xs={12} sm={12} md={5} sx={{p:2}} >
                <Paper sx={{ padding: 2, mb: 2, height: '100%', bgcolor: 'black', color: 'white' }}>
                    <Image style={{height: imageHeight }} resizeMode="contain" 
                        source={{uri: 'https://nbfire-images.s3.amazonaws.com/world-times.webp',}} 
                        alt={'World Times Map'}
                    />                  
                </Paper>
            </Grid>
            <Grid item xs={12} sm={12} md={7} sx={{p:2}} >
                <Paper sx={{ padding: 2, mb: 2, pr: { sm: 2, md: 30 }, height: '100%', bgcolor: 'black', color: 'white' }}>
                    <Box display="flex" justifyContent="center">
                        <Typography variant="h4" sx={{ mb: 2}}>In 2024, manually running reports is out and automation is in.</Typography>
                    </Box>
                    <Box display="flex" justifyContent="center">
                        <Typography variant="h6" sx={{ mb: 2, pl: 4, pr: 4 }}>With NBFire you can upload and schedule unlimited notebooks within any timezone. <b>Free</b> trial available which can be easily upgraded.</Typography>
                    </Box>
                    <Box display="flex" justifyContent="center">
                        <GetStartedButton/>
                    </Box>                    
                </Paper>
            </Grid>
            <Grid item xs={12} sx={{p:2}} >
                <Paper sx={{ padding: 2, mb: 2, pl: { sm: 2, md: 30 }, pr: { sm: 2, md: 30 }, height: '100%', bgcolor: '#90a4ae', color: 'black' }}>
                    <Box display="flex" justifyContent="center">
                        <Typography variant="h4" sx={{ mb: 2, color:'black'}}>Monthly subscriptions with no contract and 1 month free trial available.</Typography>
                    </Box>
                    <Box display="flex" justifyContent="center">
                        <Typography variant="h6" sx={{ mb: 2, pl: 4, pr: 4 }}>Start scheduling your notebooks today.</Typography>
                    </Box>
                    <Box display="flex" justifyContent="center">
                        <GetStartedButton/>
                    </Box>                     
                </Paper>
            </Grid>
            <Grid item xs={12} sm={12} md={7} sx={{p:2}} >
                <Paper sx={{ padding: 2, mb: 2, pl: { sm: 2, md: 30 }, height: '100%', bgcolor: 'black', color: 'white' }}>
                    <Box display="flex" justifyContent="center">
                        <Typography variant="h4" sx={{ mb: 2}}>Try NBFire now and experience up to 1000 Jupyter Notebook runs per month at no cost for the first month*.</Typography>
                    </Box>
                    <Box display="flex" justifyContent="center">
                        <Typography variant="h6" sx={{ mb: 2, pl: 4, pr: 4 }}>Free 1 month trial available for new users. $8/month after the free trial.</Typography>
                    </Box>
                    <Box display="flex" justifyContent="center">
                        <GetStartedButton/>
                    </Box>                     
                </Paper>
            </Grid>
            <Grid item xs={12} sm={12} md={5} sx={{p:2}} >
                <Paper sx={{ padding: 2, mb: 2, height: '100%', bgcolor: 'black', color: 'white' }}>
                    <Image style={{height: imageHeight }} resizeMode="contain" 
                        source={{uri: 'https://nbfire-images.s3.amazonaws.com/automate-things-image.webp',}} 
                        alt={'Automate things Image by Freepik'}
                    />
                </Paper>
            </Grid>
            <Grid item xs={12} sx={{p:2}} >
                <Paper sx={{ padding: 2, mb: 2, height: '100%', bgcolor: 'black', color: 'white' }}>
                    <Accordion>
                        <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="faq1-content" id="faq1-header" sx={{ bgcolor: '#607d8b', color: 'black', pl: { sm: 2, md: 30 }, pr: { sm: 2, md: 30 } }}>
                        What is NBFire?
                        </AccordionSummary>
                        <AccordionDetails sx={{ bgcolor: '#b0bec5', color: 'black', pl: { sm: 2, md: 30 }, pr: { sm: 2, md: 30 } }}>
                        NBFire is cloud platform for Scheduling your Jupyter Notebooks, allowing you to focus on writing analytics rather than hosting infrastructure.
                        </AccordionDetails>
                    </Accordion>
                    <Accordion>
                        <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="faq2-content" id="faq2-header" sx={{ bgcolor: '#607d8b', color: 'black', pl: { sm: 2, md: 30 }, pr: { sm: 2, md: 30 } }}>
                        How will NBFire save me time?
                        </AccordionSummary>
                        <AccordionDetails sx={{ bgcolor: '#b0bec5', color: 'black', pl: { sm: 2, md: 30 }, pr: { sm: 2, md: 30 } }}>
                        By providing a resiliant and secure cloud platform to run and schedule your Jupyter Notebooks, you no longer have to manage infrastructure yourself!
                        </AccordionDetails>
                    </Accordion>
                    <Accordion>
                        <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="faq3-content" id="faq3-header" sx={{ bgcolor: '#607d8b', color: 'black', pl: { sm: 2, md: 30 }, pr: { sm: 2, md: 30 } }}>
                        How is NBFire different to Jupyter Scheduler?
                        </AccordionSummary>
                        <AccordionDetails sx={{ bgcolor: '#b0bec5', color: 'black', pl: { sm: 2, md: 30 }, pr: { sm: 2, md: 30 } }}>
                        Jupyter Scheduler requires you to manage and run your own instance of Jupyter Hub. As a cloud native platform NBFire allows you to upload and immediatley run and schedule notebooks without the hassle of setting up you own infrastructure.
                        </AccordionDetails>
                    </Accordion>
                    <Accordion>
                        <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="faq4-content" id="faq4-header" sx={{ bgcolor: '#607d8b', color: 'black', pl: { sm: 2, md: 30 }, pr: { sm: 2, md: 30 } }}>
                        How many runs does NBFire offer per month?
                        </AccordionSummary>
                        <AccordionDetails sx={{ bgcolor: '#b0bec5', color: 'black', pl: { sm: 2, md: 30 }, pr: { sm: 2, md: 30 } }}>
                        Up to 1000 runs per month depending on the subscription type, however if you need more please <Link style={linkStyle} to="/contact">Contact Us</Link>.
                        </AccordionDetails>
                    </Accordion>
                    <Accordion>
                        <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="faq5-content" id="faq5-header" sx={{ bgcolor: '#607d8b', color: 'black', pl: { sm: 2, md: 30 }, pr: { sm: 2, md: 30 } }}>
                        How many notebooks can I upload?
                        </AccordionSummary>
                        <AccordionDetails sx={{ bgcolor: '#b0bec5', color: 'black', pl: { sm: 2, md: 30 }, pr: { sm: 2, md: 30 } }}>
                        As many as you need!
                        </AccordionDetails>
                    </Accordion>
                    <Accordion>
                        <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="faq6-content" id="faq6-header" sx={{ bgcolor: '#607d8b', color: 'black', pl: { sm: 2, md: 30 }, pr: { sm: 2, md: 30 } }}>
                        Is NBFire Secure?
                        </AccordionSummary>
                        <AccordionDetails sx={{ bgcolor: '#b0bec5', color: 'black', pl: { sm: 2, md: 30 }, pr: { sm: 2, md: 30 } }}>
                        Yes! The NBFire cloud platform has been built from the ground up by a team of Cyber Security experts to be secure from day one. All you Jupyter Notebooks and data will be private unless you decide to share it.
                        </AccordionDetails>
                    </Accordion>
                    <Accordion>
                        <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="faq7-content" id="faq7-header" sx={{ bgcolor: '#607d8b', color: 'black', pl: { sm: 2, md: 30 }, pr: { sm: 2, md: 30 } }}>
                        How much does it cost?
                        </AccordionSummary>
                        <AccordionDetails sx={{ bgcolor: '#b0bec5', color: 'black', pl: { sm: 2, md: 30 }, pr: { sm: 2, md: 30 } }}>
                        We offer a range of subscriptions for different prices, Just take a look <Link style={linkStyle} to="/signup">Get Started</Link>.
                        </AccordionDetails>
                    </Accordion>
                    <Accordion>
                        <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="faq8-content" id="faq8-header" sx={{ bgcolor: '#607d8b', color: 'black', pl: { sm: 2, md: 30 }, pr: { sm: 2, md: 30 } }}>
                        Is there a contract?
                        </AccordionSummary>
                        <AccordionDetails sx={{ bgcolor: '#b0bec5', color: 'black', pl: { sm: 2, md: 30 }, pr: { sm: 2, md: 30 } }}>
                        No, NBFire uses a rolling subscription that can be canceled at any time.
                        </AccordionDetails>
                    </Accordion>
                    <Accordion>
                        <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="faq9-content" id="faq9-header" sx={{ bgcolor: '#607d8b', color: 'black', pl: { sm: 2, md: 30 }, pr: { sm: 2, md: 30 } }}>
                        How many licenses will we have?
                        </AccordionSummary>
                        <AccordionDetails sx={{ bgcolor: '#b0bec5', color: 'black', pl: { sm: 2, md: 30 }, pr: { sm: 2, md: 30 } }}>
                        There is one licenese per account, if you'd like to discuss options for purchasing multiple accounts please <Link style={linkStyle} to="/contact">Contact Us</Link>.
                        </AccordionDetails>
                    </Accordion>
                    <Accordion>
                        <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="faq10-content" id="faq10-header" sx={{ bgcolor: '#607d8b', color: 'black', pl: { sm: 2, md: 30 }, pr: { sm: 2, md: 30 } }}>
                        Are subscriptions for individuals or organisations?
                        </AccordionSummary>
                        <AccordionDetails sx={{ bgcolor: '#b0bec5', color: 'black', pl: { sm: 2, md: 30 }, pr: { sm: 2, md: 30 } }}>
                        Either! There is no restriction on personal or enterprise use. If you would like to discuss the option of a dedicated enterprise instance please <Link style={linkStyle} to="/contact">Contact Us</Link>.
                        </AccordionDetails>
                    </Accordion>
                    <Box display="flex" justifyContent="center" sx={{mt: 5}}>
                        <GetStartedButton/>
                    </Box>                     
                </Paper>
            </Grid>
            <Grid item xs={12} sm={12} md={12} sx={{p:2}} >
                <Paper sx={{ padding: 2, mb: 2, pl: { sm: 2, md: 30 }, pr: { sm: 2, md: 30 }, height: '100%', bgcolor: 'black', color: 'white' }}>
                    <Box display="flex" justifyContent="center">
                        <Typography variant="h4" sx={{ mb: 2}}>Release more of your time to reveal the stories within your data.</Typography>
                    </Box>
                    <Box display="flex" justifyContent="center">
                        <GetStartedButton/>
                    </Box>                     
                </Paper>
            </Grid>
        </Grid>
    </Fragment>
  )
}

export default HomeContent